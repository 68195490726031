import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faInfinity } from '@fortawesome/free-solid-svg-icons';

const customFormatOptionLabel = ({ subscriptionSelectOpen, isoWeeks, isoWeekPeakLimits, entitlements, props }) => {
  const { label, sessionsUsed, sessionMaximum, type } = props;
  // Get keys from isoWeekPeakLimits object
  const isoWeekPeakLimitsKeys = Object.keys(isoWeekPeakLimits);

  // Find the intersection of isoWeeks Set and isoWeekPeakLimitsKeys
  const matchedKeys = isoWeekPeakLimitsKeys.filter((key) => isoWeeks.has(Number(key)));

  // Get the isoWeekPeak limit values for the matched keys
  const isoWeekPeakLimitsValues = matchedKeys.map((key) => isoWeekPeakLimits[key]);
//   {isoWeeks.size > 1 && <p className="text-xs pr-2 pl-2 pt-1 pb-1">Next week</p>}
// <div className="w-2/10 bg-xtraWhite30 rounded-tr rounded-br px-3 py-2 flex text-center flex-col min-h-[72px] max-h-[92px]">
//             <p className={`bg-white text-black rounded-full pr-2 pl-2 pt-1 pb-1 text-xs`}>Off-peak</p>
//             <p className="text-xs pr-2 pl-2 pt-3">
//               {entitlements['Peak-Hour Reservations'] ? <FontAwesomeIcon size="lg" icon={faInfinity} /> : 'Limited'}
//             </p>
//             {isoWeeks.size > 1 && (
//               <p className="text-xs pr-2 pl-2 pt-2">
//                 {entitlements['Peak-Hour Reservations'] ? <FontAwesomeIcon size="lg" icon={faInfinity} /> : 'Limited'}
//               </p>
//             )}
//           </div>
  return (
    <div className={`flex flex-col p-2`}>
      <div className="flex flex-row text-white items-center">
        <p className="text-lg font-semibold optionHeading">
          {label}{' '}
          {!subscriptionSelectOpen && (
            <FontAwesomeIcon size="xs" className="text-white" icon={faChevronDown} />
          )}
        </p>
      </div>
      {type !== 'subscription' ? (
        <div className="flex flex-row w-full items-center mt-2.5 text-white text-sm justify-between">
          <p className="text-sm">Sessions Remaining</p>
          <p>{sessionsUsed !== '' ? sessionsUsed : sessionMaximum}</p>
        </div>
      ) : (
        <div className='flex flex-col mt-2'>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-col justify-between min-h-20'>
                <p className='text-sm py-1 '>Sessions remaining</p>
                <p className='text-sm font-bold pt-2 pb-1'>This week</p>
                {
                  isoWeeks.size > 1 && (
                    <div>
                      <p className='text-sm m-0'>Next week</p>                    
                      <p className='text-[10px] mt-[-1px]'>(starts Monday)</p>
                    </div>
                  )
                }
              </div>
              <div className='flex flex-row'>
                <div className='flex flex-col justify-between min-h-20 text-center'>
                  <p className={`slot-peak rounded-full py-1 px-3 text-xs font-bold mr-4`}>Peak</p>
                  {label === 'Thrive' ? (
                    <p className="text-xs mr-4">
                      <FontAwesomeIcon size="lg" icon={faInfinity} />
                    </p>
                  ) : (
                    <p className="relative text-sm font-semibold mr-4">
                      {isoWeekPeakLimitsValues[0]} 
                    </p>
                  )}
                  {
                    isoWeeks.size > 1 && (
                      label === 'Thrive' ? (
                        <p className="text-xs mr-4">
                          <FontAwesomeIcon size="lg" icon={faInfinity} />
                        </p>
                      ) : (
                        <p className="relative text-sm mr-4">
                          {isoWeekPeakLimitsValues[1]}
                        </p>
                      )
                  )}
                </div>
                <div className='flex flex-col justify-between min-h-20 text-center'>
                  <p className={`bg-white text-black rounded-full pr-2 pl-2 pt-1 pb-1 text-xs`}>Off-peak</p>
                  <p className="text-xs pr-1">
                    {entitlements['Peak-Hour Reservations'] ? <FontAwesomeIcon size="lg" icon={faInfinity} /> : 'Limited'}
                  </p>
                  <p className="text-xs pr-1">
                    {entitlements['Peak-Hour Reservations'] ? <FontAwesomeIcon size="lg" icon={faInfinity} /> : 'Limited'}
                  </p>
                </div>
              </div>
            </div>
        </div>
      )}
    </div>
  );
};

export default customFormatOptionLabel;