import React, { useState, useCallback, useEffect } from 'react';
import SafeAreaHeader from '../components/SafeAreaHeader';
import xtraBG from '../images/xtra-bg.jpg';
import towelService from '../images/towelService.png';
import BackButton from '../components/BackButton';
import { useNavigate, useLocation } from 'react-router';
import updateCustomerTowelService from '../constants/customerTowelService';
import CircularProgressBar from '../components/CircularProgressBar';
import { useCustomer } from '../contexts/CustomerContext';

const TOWEL_OPTION = {
  RENT: 0,
  SERVICE: 1,
};

const TowelServiceOption = ({ title, price, description, selected, handleClick }) => (
  <div className="p-5 pt-4 flex flex-col rounded-md mb-2.5 bg-xtraWhite20" onClick={handleClick}>
    <div className="flex flex-row justify-between items-center">
      <h6>{title}</h6>
      <h6
        className={`rounded-2xl w-20 h-8 flex justify-center items-center text-sm ${
          selected ? 'bg-xtraAqua' : 'border border-white '
        }`}
      >
        {selected ? 'Selected' : 'Select'}
      </h6>
    </div>
    <p className="text-sm mb-5">{price}</p>
    <p className="text-sm">{description}</p>
  </div>
);

const TowelService = () => {
  const navigate = useNavigate();
  const { customerLoading, customerActiveMembership } = useCustomer();
  const { state } = useLocation();
  const { site, sessionId } = state;
  const [selected, setSelected] = useState(TOWEL_OPTION.RENT);
  const [loading, setLoading] = useState(true);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [rentalDetails, setRentalDetails] = useState(null);

  const getTowelServiceMessage = useCallback(async () => {
    if (customerActiveMembership) {
      let updateTowel = await updateCustomerTowelService(true, 1);
      if (updateTowel) {
        if (updateTowel.success && updateTowel.message) {
          setServiceDetails(updateTowel);
        }
      }
    }
  }, [customerActiveMembership]);

  const getRentalMessage = async () => {
    let rentalTowelMessage = await updateCustomerTowelService(true, 1, true);
    if (rentalTowelMessage) {
      if (rentalTowelMessage.success && rentalTowelMessage.message) {
        setRentalDetails(rentalTowelMessage);
      }
    }
    setLoading(false);
  };

  const handleContinueClick = () => {
    navigate(`/towel-access`, {
      state: {
        prevPage: 'towel-service',
        towelServiceType: selected === 0 ? 'rent' : 'service',
        site: site,
        sessionId: sessionId,
      },
    });
  };

  useEffect(() => {
    getTowelServiceMessage();
  }, [getTowelServiceMessage]);

  useEffect(() => {
    getRentalMessage();
  }, []);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-cover bg-center p-6 overflow-y-auto pb-9"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />

      <div className="flex flex-row items-center mb-4">
        <BackButton />
        <h6>Towel service</h6>
      </div>
      <img src={towelService} className="w-full h-24 mb-5" alt="towel service" />
      <div className="px-2">
        <h5 className="mb-2.5 max-w-[90%]">Your membership doesn't include towel service</h5>
        <p className="mb-5 max-w-[90%]">
          To access the towel cabinet, please choose from one of the following options.
        </p>
      </div>
      {loading || customerLoading ? (
        <div className="flex flex-col justify-center items-center">
          <CircularProgressBar />
        </div>
      ) : (
        <div>
          <TowelServiceOption
            title="Rent a towel"
            price={`$${rentalDetails?.price} once-off`}
            description="Use a towel for this session"
            selected={selected === TOWEL_OPTION.RENT}
            handleClick={() => setSelected(TOWEL_OPTION.RENT)}
          />
          {serviceDetails && (
            <TowelServiceOption
              title="Towel service"
              price={`$${serviceDetails.totalUpgradePrice / 4} a week`}
              description="A fresh towel for every session"
              selected={selected === TOWEL_OPTION.SERVICE}
              handleClick={() => setSelected(TOWEL_OPTION.SERVICE)}
            />
          )}
        </div>
      )}
      <button className="mt-8" onClick={handleContinueClick}>
        Continue
      </button>
    </div>
  );
};

export default TowelService;
