import React, { useState, useEffect } from 'react';
import SafeAreaHeader from '../../components/SafeAreaHeader';
import xtraBG from '../../images/xtra-bg.jpg';
import towels from '../../images/towels.png';
import fridge from '../../images/fridge.png';
import logout from '../../images/logout.png';
import lock from '../../images/lock.jpg';
import gate from '../../images/turnstile.jpg';
import frontDoor from '../../images/frontDoor.jpg';

import CircularProgressBar from '../../components/CircularProgressBar';
import { useNavigate, useLocation } from 'react-router-dom';
import TabBarNavigator from '../../components/TabBarNavigator';
import { ReactComponent as PlanIcon } from '../../assets/tabBar/Plan.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import SlideUpDrawer from '../../components/SlideUpDrawer';
import renderToastSuccess from '../../renderFunctions/renderToastSuccess';
import renderToastError from '../../renderFunctions/renderToastError';
import LockerDetailPopup from '../../components/LockerDetailPopup';
import Api from '../../components/Api';
import { useAppSettings } from '../../contexts/AppSettingsContext';

const SessionOption = ({ image, title, description, handleClick }) => (
  <div className="bg-xtraNavy rounded-lg p-4 flex flex-row gap-2.5 items-center" onClick={handleClick}>
    <img src={image} className="h-20 w-20 rounded-lg object-cover" alt="option" />
    <div className="flex flex-1 flex-row justify-between items-center">
      <div className="flex flex-col justify-center gap-1 max-w-[70%]">
        <h5>{title}</h5>
        <p>{description}</p>
      </div>
      <div className="w-12 h-12 flex justify-center items-center bg-xtraAqua rounded-full text-center align-middle">
        <FontAwesomeIcon icon={faArrowRight} size="xl" className="text-white" />
      </div>
    </div>
  </div>
);

const CurrentSession = () => {
  const navigate = useNavigate();
  const { notices } = useAppSettings();
  const { state } = useLocation();
  const { startTime, duration } = state;

  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);
  const [site, setSite] = useState(null);
  const [showSlideUp, setShowSlideUp] = useState(false);
  const [lockerDetails, setLockerDetails] = useState(null);
  const [lockerLoading, setLockerLoading] = useState(false);
  const [lockerDrawer, setLockerDrawer] = useState(false);
  const [lockerLargeDrawer, setLockerLargeDrawer] = useState(false);
  const [lockerReassignDrawer, setLockerReassignDrawer] = useState(false);
  const [lockerLargeReassignDrawer, setLockerLargeReassignDrawer] = useState(false);
  const [showLockerPopup, setShowLockerPopup] = useState(false);
  const [showOpenDoor, setShowOpenDoor] = useState(false);
  const [showOpenGate, setShowOpenGate] = useState(false);
  const [showOpenFridge, setShowOpenFridge] = useState(false);
  const [showOpenTowel, setShowOpenTowel] = useState(false);

  const getXtraSession = async () => {
    let xtra = await Api('/session/xtra', 'GET');
    if (xtra.status === 200 && xtra.data?.session) {
      setSession(xtra.data.session);
      setSite(xtra.data.session.site);
      if (xtra.data.locker) {
        setLockerDetails({
          locker_number: xtra.data.locker.lockerNumber,
          locker_code: xtra.data.locker.lockerCode,
          locker_type: xtra.data.locker.lockerType,
        });
      }
    }
    setLoading(false);
  };

  const handleEndSessionClick = () => {
    endSession();
  };

  const endSession = async () => {
    if (session) {
      let xtra = await Api('/session/xtra/end', 'GET');
      if (xtra.status === 200) {
        if (xtra.data.success) {
          if (xtra.data.message) {
            renderToastSuccess({
              message: xtra.data.message,
            });
            navigate('/xtra/sessions');
          }
        }
      }
    } else {
      renderToastSuccess({
        message: 'You successfully ended your session. We hope to see you again soon.',
      });
      navigate('/xtra/sessions');
    }
  };

  const handleAssignLocker = async () => {
    setLoading(true);
    const locker = await Api('/locker/xtra/assign', 'POST', {
      site: {
        _id: '65c468e98af2a3e02af945ae',
      },
    });

    if (locker.status === 200) {
      let result = locker.data;
      if (result.success) {
        if (result.locker !== null) {
          setLockerDetails({
            locker_number: result.locker.lockerNumber,
            locker_code: result.locker.lockerCode,
            locker_type: result.locker.lockerType,
          });
          if (result.session) {
            setSession(result.session);
            setSite(result.session.site);
          }

          setShowLockerPopup(true);
        } else {
          renderToastError({
            message: 'Sorry, there are no lockers available in this session.',
          });
        }
      } else {
        renderToastError({
          message: result.message,
        });
      }
    } else {
      renderToastError({
        message:
          locker?.response?.data?.message ||
          'Failed to assign new locker, please try again or contact us for assistance',
      });
    }
    setLoading(false);
  };

  const handleRequestTallLocker = async () => {
    setLockerLoading(true);
    const locker = await Api('/locker/xtra/assign/large', 'POST', {
      session: session,
      site: site,
    });

    if (locker.status === 200) {
      let result = locker.data;
      if (result.success) {
        if (result.locker !== null) {
          setLockerDetails({
            locker_number: result.locker.lockerNumber,
            locker_code: result.locker.lockerCode,
            locker_type: result.locker.lockerType,
          });

          setLockerLoading(false);
          setShowLockerPopup(true);
        } else {
          setLockerDetails(null);
          renderToastError({
            message: 'Sorry, there are no lockers available in this session.',
          });
          setLockerLoading(false);
        }
      } else {
        renderToastError({
          message: result.message,
        });
        setLockerLoading(false);
      }
    } else {
      renderToastError({
        message:
          locker?.response?.data?.message ||
          'Failed to assign new locker, please try again or contact us for assistance',
      });
      setLockerLoading(false);
    }
  };

  const handleReAssignLocker = async (large = false) => {
    setLockerLoading(true);
    const locker = await Api('/locker/xtra/reassign', 'POST', {
      session: session,
      site: site,
      large: large,
    });

    if (locker.status === 200) {
      let result = locker.data;
      if (result.success) {
        if (result.locker !== null) {
          setLockerDetails({
            locker_number: result.locker.lockerNumber,
            locker_code: result.locker.lockerCode,
            locker_type: result.locker.lockerType,
          });

          setLockerLoading(false);
          setShowLockerPopup(true);
        } else {
          setLockerDetails(null);
          renderToastError({
            message: 'Sorry, there are no lockers available in this session.',
          });
          setLockerLoading(false);
        }
      } else {
        renderToastError({
          message: result.message,
        });
        setLockerLoading(false);
      }
    } else {
      renderToastError({
        message:
          locker?.response?.data?.message ||
          'Failed to assign new locker, please try again or contact us for assistance',
      });
      setLockerLoading(false);
    }
  };

  const getLock = (doors, type) => {
    if (doors) {
      if (type === 'door') {
        return doors.FrontDoor;
      } else if (type === 'gate') {
        return doors.Turnstile;
      } else if (type === 'towel cabinet') {
        return doors.TowelCabinet;
      } else if (type === 'fridge') {
        return doors.Fridge;
      }
    }

    return null;
  };

  const handleOpenClick = async (type) => {
    setLoading(true);
    const reponse = await Api(`/site/${'65c468e98af2a3e02af945ae'}`, 'GET', null);
    const activeSite = reponse.data.site;
    const response = await Api(`/kisi/locks/${getLock(activeSite.doors, type)}/unlock`, 'GET');
    if (response.status === 200) {
      let result = response.data;
      if (result.message) {
        if (result.message !== 'Unlocked!') {
          renderToastError({
            message: `There was an issue opening the ${type}, please try again or contact us for help.`,
          });
        } else {
          renderToastSuccess({
            message: `The ${type} has been opened!`,
          });
        }
      }
    } else {
      renderToastError({
        message: `There was an issue opening the ${type}, please try again or contact us. ` + getLock(type),
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getXtraSession();
  }, []);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-cover bg-center p-6 overflow-y-auto pb-9"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex justify-between">
        <div className="flex flex-row items-center">
          <h3 className="mb-3">Your session</h3>
        </div>
        <img src={logout} className="w-5 h-5 object-fit" alt="logout" onClick={() => navigate('/logout')} />
      </div>
      {loading ? (
        <CircularProgressBar />
      ) : (
        <div className="flex flex-col gap-2.5 overflow-scroll max-h-[75vh]">
          <div className="p-5 flex flex-col gap-3 rounded-lg bg-xtraWhite20">
            <div className="flex flex-row justify-between items-center">
              <p>Started</p>
              <p className="font-bold">
                {session !== null
                  ? moment(session.startTimeUTC).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('h:mmA')
                  : moment(startTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('h:mmA')}
              </p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>Duration</p>
              <p className="font-bold">{session !== null ? session.duration : duration} minutes</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <p>End</p>
              <p className="font-bold">
                {moment(session ? session.startTimeUTC : startTime)
                  .add(session !== null ? session.duration : duration, 'minutes')
                  .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                  .format('h:mmA')}
              </p>
            </div>
            <button
              onClick={() => {
                setShowSlideUp(true);
              }}
            >
              End session
            </button>
            <p className="text-sm align-middle text-center">
              <FontAwesomeIcon icon={faInfoCircle} /> You're welcome to stay longer (charges apply)
            </p>
          </div>
          {notices.map((notice, index) =>
            notice.disabled ? null : (
              <div key={index} className="bg-xtraNavy rounded-lg p-4 flex flex-col">
                <div className="flex-grow-0 flex flex-row relative rounded-3xl bg-xtraOrange py-0.5 justify-center text-center items-center max-w-24 mb-2">
                  <p className="text-sm font-bold items-center">Notice</p>
                </div>
                <h6>{notice.title}</h6>
                <p className="text-sm mt-1">{notice.description}</p>
              </div>
            )
          )}
          <SessionOption
            image={frontDoor}
            title="Door"
            description="Open the door"
            handleClick={() => setShowOpenDoor(true)}
          />
          <SessionOption
            image={gate}
            title="Gate"
            description="Open the gate"
            handleClick={() => setShowOpenGate(true)}
          />
          <SessionOption
            image={lock}
            title={lockerDetails !== null ? `Locker ${lockerDetails.locker_number}` : 'Lockers'}
            description={lockerDetails !== null ? `Press to reveal code` : 'Reserve a locker'}
            handleClick={() => {
              lockerDetails !== null ? setShowLockerPopup(true) : setLockerDrawer(true);
            }}
          />
          <SessionOption
            image={towels}
            title="Towels"
            description={'Open the towel cabinet'}
            handleClick={() => setShowOpenTowel(true)}
          />
          <SessionOption
            image={fridge}
            title="Fridge"
            description="Open the fridge"
            handleClick={() => setShowOpenFridge(true)}
          />
          <SlideUpDrawer
            setShow={setShowSlideUp}
            show={showSlideUp}
            action={() => {
              handleEndSessionClick();
            }}
            actionText={'End Session'}
            actionHeading={`Are you sure you want to end your session?`}
            message={`You still have ${moment(startTime)
              .add(duration, 'minutes')
              .diff(moment(), 'minutes')} minutes left until you are required to end your session. `}
            actionRightAway={true}
          />
          {lockerDetails !== null && (
            <LockerDetailPopup
              details={lockerDetails}
              handleRequestTallLocker={() => {
                setShowLockerPopup(false);
                setLockerLargeDrawer(true);
              }}
              handleReAssignTallLocker={() => {
                setShowLockerPopup(false);
                setLockerLargeReassignDrawer(true);
              }}
              handleReAssignLocker={() => {
                setShowLockerPopup(false);
                setLockerReassignDrawer(true);
              }}
              loading={lockerLoading}
              showLockerPopup={showLockerPopup}
              setShowLockerPopup={setShowLockerPopup}
            />
          )}
          <SlideUpDrawer
            setShow={setLockerDrawer}
            show={lockerDrawer}
            action={() => {
              handleAssignLocker();
            }}
            actionText={'Assign Locker'}
            actionHeading={`Reserve a locker`}
            message={`We will assign you a locker number and its code, would you like to continue?`}
            actionRightAway={true}
          />
          <SlideUpDrawer
            setShow={setLockerLargeDrawer}
            show={lockerLargeDrawer}
            action={() => {
              handleRequestTallLocker();
            }}
            actionText={'Request for Tall Locker'}
            actionHeading={`Re-Assign to a tall locker`}
            message={`We will try and re-assign you a tall locker, would you like to continue?`}
            actionRightAway={true}
          />
          <SlideUpDrawer
            setShow={setLockerReassignDrawer}
            show={lockerReassignDrawer}
            action={() => {
              handleReAssignLocker();
            }}
            actionText={'Re-Assign Locker'}
            actionHeading={`Re-Assign locker`}
            message={`Has the locker you have been given too hard for you to reach? Or are there some items already in the provided locker? Try and re-assign to a new locker.`}
            actionRightAway={true}
          />
          <SlideUpDrawer
            setShow={setLockerLargeReassignDrawer}
            show={lockerLargeReassignDrawer}
            action={() => {
              handleReAssignLocker(true);
            }}
            actionText={'Re-Assign Tall Locker'}
            actionHeading={`Re-Assign tall locker`}
            message={`Has the locker you have been given too hard for you to reach? Or are there some items already in the provided locker? Try and re-assign to a new tall locker.`}
            actionRightAway={true}
          />
          <SlideUpDrawer
            setShow={setShowOpenGate}
            show={showOpenGate}
            action={() => handleOpenClick('gate')}
            actionText={'Open Gate'}
            actionHeading={`Open Gate`}
            message={`Do you need to open the gate to enter the club?`}
            actionRightAway={true}
          />
          <SlideUpDrawer
            setShow={setShowOpenDoor}
            show={showOpenDoor}
            action={() => handleOpenClick('door')}
            actionText={'Open Door'}
            actionHeading={`Open Door`}
            message={`Do you need to open the door to enter the club?`}
            actionRightAway={true}
          />
          <SlideUpDrawer
            setShow={setShowOpenTowel}
            show={showOpenTowel}
            action={() => handleOpenClick('towel cabinet')}
            actionText={'Open Towel Cabinet'}
            actionHeading={`Open Towel Cabinet`}
            message={`Do you need to open the towel cabinet?`}
            actionRightAway={true}
          />
          <SlideUpDrawer
            setShow={setShowOpenFridge}
            show={showOpenFridge}
            action={() => handleOpenClick('fridge')}
            actionText={'Open Fridge'}
            actionHeading={`Open Fridge`}
            message={`Do you need to open the fridge?`}
            actionRightAway={true}
          />
        </div>
      )}
      <TabBarNavigator
        tabs={[
          {
            name: 'Session',
            Icon: PlanIcon,
            route: `/xtra/session`,
          },
        ]}
        paddingHorizontal={80}
      />
    </div>
  );
};

export default CurrentSession;
