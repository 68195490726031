import Api from '../components/Api';

const getPurchasedPeakSessions = async (siteid) => {
  var siteidcheck = '';
  if (siteid) {
    siteidcheck = `?siteid=${siteid}`;
  }
  const purchases = await Api(`/customer/membership/peakvisitusage${siteidcheck}`, 'GET', null);

  if (purchases.status === 200) {
    const result = purchases.data;
    if (result.success) {
      return result.peakSessionsAvailable;
    }
  } else {
    return [];
  }
};

export default getPurchasedPeakSessions;
