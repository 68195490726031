import Api from '../components/Api';
import renderToastError from '../renderFunctions/renderToastError';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';

const handlePurchaseSinglePeakVisit = async ({
  returnPrice,
  setDisplaySinglePeakVisitPurchase,
  setPeakVisitPurchaseLoading,
  setPeakVisitLoading,
  setSinglePeakVisitInfo,
  siteSelected = '65c468e98af2a3e02af945ae',
  successFunctionCalls,
}) => {
  // console.log("handle purchse peak : ", returnPrice);
  setDisplaySinglePeakVisitPurchase(true);
  if (returnPrice === 0) {
    setPeakVisitPurchaseLoading?.(true);
  }

  const purchaseSinglePeak = await Api(
    `/customer/membership/purchase/singlepeakvisit?returnPrice=${returnPrice}&siteid=${siteSelected}`,
    'GET'
  );
  if (purchaseSinglePeak.status === 200) {
    let result = purchaseSinglePeak.data;
    if (result.success) {
      if (returnPrice === 1) {
        setSinglePeakVisitInfo(result.peakSession);
      } else {
        successFunctionCalls();
        setTimeout(() => {
          renderToastSuccess({
            message: result.peakSession?.message ?? 'Single peak visit purchased!',
          });
        }, 500);
        setDisplaySinglePeakVisitPurchase(false);
        setPeakVisitPurchaseLoading?.(false);
      }
    } else {
      renderToastError({
        message: result.message,
      });
    }
    setPeakVisitLoading(false);
  } else {
    renderToastError({
      message: 'Unable to purchase a single peak visit. Please contact our support for further assistance.',
    });
  }
};

export default handlePurchaseSinglePeakVisit;
