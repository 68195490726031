import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import Api from '../components/Api';
import * as Sentry from '@sentry/react';

export const CustomerContext = createContext();

export const CustomerContextProvider = ({ children }) => {
  const [customer, setCustomer] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(false);

  const customerSubscription = customer?.subscription;
  const customerEntitlements = customerSubscription?.entitlements;
  const customerSessionPacks = customerSubscription?.sessionPacks ?? [];
  const customerGifts = customerSubscription?.gifts ?? [];
  const customerSessionPacksAndGifts = [...customerSessionPacks, ...customerGifts];
  const customerActiveMembership = customerSubscription?.userSubscriptions?.find((sub) => sub.status === 'active');
  const customerOnHoldMembership = customerActiveMembership
    ? null
    : customerSubscription?.userSubscriptions?.find((sub) => sub.status === 'on-hold');

  customerSubscription?.userSubscriptions?.sort((a, b) => b.subscription_id - a.subscription_id);

  const theSubscription =
    customerSubscription?.userSubscriptions?.length > 0 ? customerSubscription.userSubscriptions[0] : null;

  const customerMembership = theSubscription;
  const customerMembershipOnHold = !customerActiveMembership && customerOnHoldMembership;
  const customerMembershipAwaitingCancellation = customerMembership?.cancellationDate !== '';
  const customerMembershipProduct = customerMembership?.products?.find((product) =>
    product.categories?.includes('Membership Packages')
  );
  const customerMembershipHasExtendedSession = customerMembership?.products?.some(
    (product) => product.categories.includes('Add-On') && product.product_name === 'Extended session time'
  );
  const customerMembershipProductName = customerMembershipProduct?.product_name;

  const getCustomer = useCallback(async ({ update } = {}) => {
    setCustomerLoading(true);
    const query = update ? '?update=true' : '';
    const response = await Api(`/customer${query}`, 'GET');
    if (response.status === 200 && response.data.customer) {
      let cust = response.data.customer;
      if (cust.billing_details) {
        cust = { ...cust, ...cust.billing_details };
      }
      setCustomer(cust);
    }
    setCustomerLoading(false);
  }, []);

  useEffect(() => {
    Sentry.setUser(customer ? { email: customer.email } : null);
  }, [customer]);

  return (
    <CustomerContext.Provider
      value={{
        customer,
        customerSubscription,
        customerEntitlements,
        customerSessionPacksAndGifts,
        customerMembership,
        customerMembershipOnHold,
        customerMembershipAwaitingCancellation,
        customerMembershipProduct,
        customerMembershipProductName,
        customerMembershipHasExtendedSession,
        getCustomer,
        customerLoading,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = ({ refetch = true, update = false } = {}) => {
  const context = useContext(CustomerContext);

  if (!context) {
    throw new Error('useCustomer must be used within a CustomerContextProvider');
  }

  const { getCustomer } = context;

  useEffect(() => {
    if (refetch) {
      getCustomer({ update });
    }
  }, [refetch, update, getCustomer]);

  return context;
};
