import React, { useState, useEffect } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import BackButton from '../components/BackButton';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import SlideUpDrawer from '../components/SlideUpDrawer';
import renderToastError from '../renderFunctions/renderToastError';
import Api from '../components/Api';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import { useNavigate } from 'react-router';
import CircularProgressBar from '../components/CircularProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import getCustomerPauses from '../constants/customerPauses';

const PauseMembership = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [pauseSchedule, setPauseSchedule] = useState([]);
  const [arryOfDates, setArrayOfDates] = useState([]);
  const [activeDaysPaused, setActiveDaysPaused] = useState(0);

  const now = moment();
  const maxEndDate = moment().add(12, 'months');

  const lastPause = pauseSchedule.length > 0 ? pauseSchedule[pauseSchedule.length - 1] : null;
  const activePause = lastPause && moment(lastPause.startDateUTC).isAfter(now) ? lastPause : null;

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate && moment(endDate).isSameOrBefore(moment(date).add(7, 'days'))) {
      setEndDate(null); // Reset end date if it's before new start date
    }
  };

  const handleEndDateChange = (date) => {
    if (!startDate || (startDate && moment(date).isSameOrAfter(moment(startDate).add(7, 'days')))) {
      setEndDate(date);
    } else {
      renderToastError({
        message: 'You can pause 7 days at a minumum.',
      });
    }
  };

  const undoPauseMembership = async () => {
    setLoading(true);
    if (activePause) {
      try {
        const pause = await Api('/customer/membership/pause', 'DELETE', {
          pauseId: activePause._id,
        });

        if (pause.status === 200) {
          let result = pause.data;
          if (result.success) {
            retrieveCustomerPauseSchedule(true);
            renderToastSuccess({
              message: result.message,
              autoClose: 3000,
              // onClose: () => navigate(-1),
            });
          } else {
            renderToastError({
              message: 'Failed to pause membership, please contact us for more information.',
            });
          }
        } else {
          renderToastError({
            message: pause.response.data.message,
          });
        }
      } catch (error) {
        console.log('error: ', error);
        renderToastError({
          message: error.response.data.message,
        });
      }
    } else {
      renderToastError({
        message: 'Both a start and end date must be selected to confirm pause',
      });
    }
    setLoading(false);
  };

  const pauseMemberhip = async () => {
    setLoading(true);
    if (startDate && endDate) {
      try {
        const pause = await Api('/customer/membership/pause', 'POST', {
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
        });

        if (pause.status === 200) {
          let result = pause.data;
          if (result.success) {
            retrieveCustomerPauseSchedule();
            renderToastSuccess({
              message: result.message,
              autoClose: 3000,
              // onClose: () => navigate(-1),
            });
          } else {
            renderToastError({
              message: 'Failed to pause membership, please contact us for more information.',
            });
          }
        } else {
          renderToastError({
            message: pause.response.data.message,
          });
        }
      } catch (error) {
        console.log('error: ', error);
        renderToastError({
          message: error.response.data.message,
        });
      }
    } else {
      renderToastError({
        message: 'Both a start and end date must be selected to confirm pause',
      });
    }
    setLoading(false);
  };

  const retrieveCustomerPauseSchedule = async () => {
    const pauses = await getCustomerPauses();
    // console.log('subscripton: ', subscription);
    console.log('pauses', pauses);
    if (pauses) {
      setPauseSchedule(pauses);
      let datesArray = [];
      let numOfDays = 0;
      pauses.forEach((pauseSchedule) => {
        // Extract start and end dates
        const startDate = new Date(pauseSchedule.startDateUTC);
        const endDate = new Date(pauseSchedule.endDateUTC);
        if (!pauseSchedule.excludeFromCharges) {
          numOfDays += moment(endDate).diff(moment(startDate), 'days');
        }

        // Loop through dates between start and end date
        const now = new Date();
        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
          // Convert date to YYYY-MM-DD format
          const formattedDate = date.toISOString().split('T')[0];

          // Check if the date already exists in the array
          if (!datesArray.includes(formattedDate) && date > now) {
            // If date doesn't exist, push it into the array
            datesArray.push(new Date(formattedDate));
          }
        }
      });

      setArrayOfDates(datesArray);
      setActiveDaysPaused(numOfDays);
    }
    setLoading(false);
  };

  useEffect(() => {
    retrieveCustomerPauseSchedule();
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      console.log('both set');
      const pauseMembershipScroll = document.getElementById('pauseMembershipScroll');
      console.log('pauseMembershipScroll: ', pauseMembershipScroll);
      if (pauseMembershipScroll) {
        const scrollDifference = pauseMembershipScroll.scrollHeight - pauseMembershipScroll.clientHeight;
        pauseMembershipScroll.scrollTo({
          top: scrollDifference,
          behavior: 'smooth',
        });
      }
    }
  }, [startDate, endDate]);

  const renderPauseSchedule = (item, upcoming = true) => {
    const startDate = moment(item.startDateUTC).format('Do MMM, YY');
    const endDate = moment(item.endDateUTC).format('Do MMM, YY');
    const numOfDays = moment(item.endDateUTC).diff(moment(item.startDateUTC), 'days');

    if (numOfDays <= 0) return null;

    return upcoming ? (
      <div key={item._id} className={`flex flex-row justify-between items-center rounded p-2 bg-xtraBlack30 mb-2`}>
        <div className="flex flex-col w-full">
          <div className="flex flex-row gap-2">
            <p className="text-xs">
              Membership pause between {startDate} and {endDate}
            </p>
            {item.excludeFromCharges && <p className="text-xs opacity-50">Free</p>}
          </div>
        </div>
      </div>
    ) : (
      <div key={item._id} className={`flex flex-row justify-between items-center rounded p-2 bg-xtraBlack30 mt-2`}>
        <div className="flex flex-col w-full">
          <div className="flex flex-row gap-2">
            <p className="text-xs">
              {numOfDays} day{numOfDays > 1 && 's'} - {startDate} to {endDate}
            </p>
            {item.excludeFromCharges && <p className="text-xs opacity-50">Free</p>}
          </div>
        </div>
      </div>
    );
  };

  const newTotal = startDate && endDate ? moment(endDate).diff(moment(startDate), 'days') + activeDaysPaused : null;

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover py-6"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-row items-center mx-6">
        <BackButton />
        <h6>Pause membership</h6>
      </div>
      {loading ? (
        <div className="flex justify-center">
          <CircularProgressBar />
        </div>
      ) : (
        <div
          id={'pauseMembershipScroll'}
          className={`flex-col py-6 overflow-y-scroll ${
            (startDate && endDate) || activePause ? 'h-[68vh]' : 'h-[80vh]'
          }`}
        >
          <div className="px-6">
            <div className="py-3 px-5 flex flex-row gap-3 rounded-lg text-sm bg-xtraWhite20 mb-2.5">
              <div className="flex justify-center items-center rounded-full bg-xtraOrange w-4 h-4">
                <FontAwesomeIcon icon={faExclamation} size="xs" className="text-white" />
              </div>
              <p className="flex-1 text-sm">
                You can pause your membership for <b>free for up to 4 weeks in a 52 week period</b>. We charge a{' '}
                <b>$5 holding fee per week beyond</b> the initial 4 weeks.
              </p>
            </div>
            <div className="py-3 px-5 flex flex-row gap-3 rounded-lg text-sm bg-xtraWhite20 mb-2.5">
              <div className="flex justify-center items-center rounded-full bg-xtraOrange w-4 h-4">
                <FontAwesomeIcon icon={faExclamation} size="xs" className="text-white" />
              </div>
              <p className="flex-1 text-sm">You can pause 7 days at a minimum.</p>
            </div>
          </div>
          <div className="acc-row acc-row-last px-6 py-5 mb-7">
            <p className="text-sm">Your total days paused</p>
            <p className="text-bold">{activeDaysPaused}</p>
            {pauseSchedule.map((pause) => renderPauseSchedule(pause, false))}
          </div>
          {activePause ? (
            <div className="px-6">
              <h5 className="mb-2.5">Upcoming Pause Schedule</h5>
              {renderPauseSchedule(activePause)}
            </div>
          ) : (
            <div className="px-6">
              <div className="flex-col w-full relative ">
                <p className="text-sm">Pause start date</p>

                <input
                  type="text"
                  className="w-full text-center mt-2 mb-4"
                  value={startDate ? moment(startDate).format('Do MMMM, YY') : ''}
                  placeholder="Start Date"
                  readOnly
                  onClick={() => document.getElementById('start-datepicker').click()} // Show date picker popup on click
                />
                <DatePicker
                  id="start-datepicker"
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={now.add(1, 'days').toDate()}
                  dateFormat="yyyy-MM-dd"
                  customInput={<div style={{ display: 'none', position: 'absolute' }} />} // Hide the actual date picker input
                  popperPlacement="bottom-start"
                  popperClassName="larger-datepicker-popper"
                  excludeDates={arryOfDates}
                />
              </div>
              <div className="flex-col w-full">
                <p className="text-sm">Pause end date</p>
                <input
                  type="text"
                  className="w-full text-center mt-2 mb-4"
                  value={startDate ? (endDate ? moment(endDate).format('Do MMMM, YY') : '') : ''}
                  placeholder="End Date"
                  readOnly
                  onClick={() => document.getElementById('end-datepicker').click()} // Show date picker popup on click
                />
                <DatePicker
                  id="end-datepicker"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={maxEndDate.toDate()}
                  dateFormat="yyyy-MM-dd"
                  customInput={<div style={{ display: 'none', position: 'absolute' }} />} // Hide the actual date picker input
                  popperPlacement="bottom-start"
                  popperClassName="larger-datepicker-popper"
                  excludeDates={arryOfDates}
                />
              </div>
              <div>
                <p>
                  {newTotal > 28 &&
                    `This will bring your total days paused to ${newTotal} which is past the free threshold of 28 days. You will be charged a $5 holding fee per week over 28 days.`}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
      {!loading && startDate && endDate && !activePause && (
        <div className="flex-col p-6 w-full">
          <button onClick={() => setShowDrawer(true)} className="w-full">
            Confirm Pause
          </button>
          <button onClick={() => navigate(-1)} className="w-full bg-xtraWhite30 mt-5">
            Keep Membership
          </button>
        </div>
      )}
      {!loading && activePause && (
        <div className="flex-col p-6 w-full">
          <button onClick={() => setShowDrawer(true)} className="w-full">
            Undo Upcoming Pause
          </button>
        </div>
      )}
      <SlideUpDrawer
        show={showDrawer}
        setShow={setShowDrawer}
        action={activePause ? undoPauseMembership : pauseMemberhip}
        actionText={activePause ? 'Undo Pause' : 'Pause Membership'}
        actionHeading={
          activePause
            ? 'Are you sure you want to undo upcoming pause your membership?'
            : 'Are you sure you want to pause your membership?'
        }
        actionRightAway={true}
      />
    </div>
  );
};

export default PauseMembership;
