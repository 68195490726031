import SettingsButton from './SettingsButton';
import xtraTick from '../images/xtra-tick.png';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SlideUpDrawer from '../components/SlideUpDrawer';
import Api from './Api';
import renderToastError from '../renderFunctions/renderToastError';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import SimplePopup from './SimplePopup';
import isCasual from '../constants/isCasual';
import { formatTimeSchedule } from '../renderFunctions/formatTime';

const isSlotTimeSameOrAfterNow = (slot) => {
  const hours = Math.floor(slot.time);
  const minutes = (slot.time - hours) * 60;
  const slotTime = moment(slot.date).hours(hours).minutes(minutes).add(30, 'minutes');
  const startTime = moment(slot.date).hours(hours).minutes(minutes).subtract(5, 'minutes');
  const slotStartTime = moment(slot.date).hours(hours).minutes(minutes);
  const now = moment();
  const endTime = moment(slotTime).add(10, 'minutes');
  const beforeStartTimeDiff = slotStartTime.diff(now, 'minutes');
  const afterEndTimeDiff = now.diff(slotTime, 'minutes');

  return {
    is2HoursBefore: beforeStartTimeDiff <= 120 && beforeStartTimeDiff >= 0,
    is30MinsAfter: afterEndTimeDiff <= 20 && afterEndTimeDiff >= 0,
    isFuture: slotTime.isSameOrAfter(now),
    isInBufferRange: now.isBetween(startTime, endTime),
  };
};

const ReservationItem = ({
  reservation,
  toggleSettingsMenu,
  hideStatus = false,
  getCustomerQueue = null,
  isToday,
  isFirst,
}) => {
  const navigate = useNavigate();
  const { isFuture, isInBufferRange, is2HoursBefore, is30MinsAfter } = isSlotTimeSameOrAfterNow(reservation);
  const [showShadow, setShowShadow] = useState(false);

  const currentUTCTime = moment.utc();
  const reservationCreatedOnUTC = reservation.createdOnUTC;
  const reservationCreatedMoment = moment.utc(reservationCreatedOnUTC);
  const secondsDifference = currentUTCTime.diff(reservationCreatedMoment, 'seconds');

  const [showEarly, setShowEarly] = useState(false);
  const [showLate, setShowLate] = useState(false);

  const [loading, setLoading] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [schedule, setSchedule] = useState(null);
  const [isFull, setIsFull] = useState(false);
  const [showStartSessionHelp, setShowStartSessionHelp] = useState(false);

  var justAdded = null;
  if (secondsDifference <= 120) {
    justAdded = {
      borderRadius: '8px',
      boxShadow: '0px 0px 8px rgba(255, 255, 255, 1)',
      opacity: 1,
    };
    if (showShadow === false) {
      setShowShadow(true);
    }
  }

  useEffect(() => {
    var timer = null;
    timer = setTimeout(() => {
      setShowShadow(false);
    }, 4000); // 10 seconds in milliseconds

    return () => {
      setShowShadow(false);
      clearTimeout(timer);
    };
  }, []);

  const checkScheduleCapacity = async (late = false) => {
    setLoading(true);
    // console.log('checking...');
    var date = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD');
    const currentTime = moment();
    const currentMinute = currentTime.minute();
    let currentHour = currentTime.hour();
    if (currentMinute >= 30) {
      currentHour += 0.5;
    }
    const time = currentHour;
    const sessionFull = await Api(
      `/schedule/checkcapacity?site=${reservation.site._id}&date=${date}&time=${time}`,
      'GET'
    );
    if (sessionFull.status === 200) {
      if (sessionFull.data.isFull) {
        setLoading(false);
        setIsFull(true);
        setConfirmMessage(
          `There is no availability in the current time slot.${
            late ? '' : ' You will need to wait for your reserved time to enter the club.'
          }`
        );
      } else if (!sessionFull.data.schedule) {
        startSession(reservation._id);
      } else {
        setConfirmMessage(
          'There is availibilty for the current session, we are able to move your reserved session. Would you like to continue?'
        );
        setSchedule(sessionFull.data.schedule);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setShowEarly(false);
      setShowLate(false);
      renderToastError({
        message:
          'Sorry, we were not able to obtain the schedule, we are unable to processes your request right now. Please try again later or contact us for assistance.',
      });
    }
  };

  const moveSchedule = async (late) => {
    const moveToSchedule = await Api('/customer/schedule/move', 'POST', {
      currentSchedule: reservation,
      moveToSchedule: schedule,
      lateArrival: late,
    });
    if (moveToSchedule.status === 200) {
      if (getCustomerQueue !== null) {
        getCustomerQueue(1);
      }
      if (moveToSchedule.data.success) {
        renderToastSuccess({
          message: moveToSchedule.data.message,
        });
      } else {
        renderToastError({
          message: moveToSchedule.data.message,
        });
      }
    } else {
      renderToastError({
        message: moveToSchedule.response.data.message,
      });
    }
  };

  const startSession = async (reservationId) => {
    try {
      const session = await Api(`/session`, 'POST', { reservationId });
      if (session.status === 200) {
        let result = session.data;
        if (result.success) {
          if (result.session !== null) {
            console.log('session: ', result.session);
            navigate(`/session/?id=${result.session._id}`);
            renderToastSuccess({
              message: 'Door is now open, session is now started.',
            });
          } else {
            renderToastError('Could not start your session, please try again, or contact us for assistance.');
          }
        }
      } else {
        renderToastError('Could not determine your location to start your session, please try again.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  if (isToday && !(isFuture || is30MinsAfter)) {
    return null;
  }

  return (
    <div
      key={reservation._id}
      id={reservation._id}
      className={`bg-xtraWhite30 rounded flex flex-col mb-3 mx-2 py-3 pr-2 pl-4 reservation-item ${
        reservation.slideUp ? 'slide-up' : ''
      } ${showShadow ? justAdded : ''}`}
      style={{
        transitionDelay: `${reservation.slideUpDelay}ms`,
        ...justAdded,
      }}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row">
          <p className="text-sm">Arrival between</p>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <p className="text-base font-bold	p-0 m-0">{formatTimeSchedule(reservation.time)}</p>
        <div className="flex flex-row items-center mt-[-20px]">
          {!hideStatus && (
            <p className="flex items-center text-xs bg-xtraSuccess h-fit text-xtraNavy px-[5px] py-[2px] font-medium mr-2 rounded">
              <img className="w-3 h-3 object-fit mr-1" src={xtraTick} alt="xtra tick" />
              Reserved
            </p>
          )}
          <SettingsButton onClick={() => toggleSettingsMenu(reservation)} />
        </div>
      </div>
      {!hideStatus && (
        <div className="flex flex-col pr-2 mt-2 gap-2">
          {isFirst && (
            <button
              className="w-full"
              style={{
                opacity: isInBufferRange ? 1 : 0.5,
              }}
              title="Start session"
              onClick={() => {
                isInBufferRange
                  ? navigate(isCasual ? '/casual/open-door' : '/tap-door', {
                      state: {
                        reservationId: reservation._id,
                      },
                    })
                  : setShowStartSessionHelp(true);
              }}
            >
              Start session
            </button>
          )}
          {is2HoursBefore && !isInBufferRange && (
            <button
              className="w-full border border-white bg-transparent whiteShadow"
              onClick={() => setShowEarly(true)}
            >
              I'm early
            </button>
          )}
          {is30MinsAfter && !isInBufferRange && (
            <button className="w-full border border-white bg-transparent whiteShadow" onClick={() => setShowLate(true)}>
              I'm late
            </button>
          )}
          {isFirst && (
            <button className="w-full border border-white bg-transparent whiteShadow" onClick={() => navigate('/help')}>
              Help
            </button>
          )}
        </div>
      )}
      {showEarly && (
        <SlideUpDrawer
          show={showEarly}
          setShow={setShowEarly}
          preAction={checkScheduleCapacity}
          action={() => moveSchedule(false)}
          actionText={'Check Schedule'}
          message={'We will check to see that the current session is not full, continue?'}
          confirmMessage={confirmMessage}
          loading={loading}
          setLoading={setLoading}
          disableContinue={isFull}
        />
      )}
      {showLate && (
        <SlideUpDrawer
          show={showLate}
          setShow={setShowLate}
          preAction={() => checkScheduleCapacity(true)}
          action={() => moveSchedule(true)}
          actionText={'Check Schedule'}
          actionClass={'redButton'}
          message={'We will check to see that the current session is not full, continue?'}
          confirmMessage={confirmMessage}
          loading={loading}
          setLoading={setLoading}
          disableContinue={isFull}
        />
      )}
      <SimplePopup
        show={showStartSessionHelp}
        setShow={setShowStartSessionHelp}
        messages={[
          'You cannot start your session until you’re within your arrival time window.',
          'Once it’s time, your start session button will go bright blue.',
          'From there, follow the prompts to get access to the club.',
          // 'In case you’re wondering, watch these videos which explain how to get into the club.',
        ]}
        buttons={[
          { text: 'I Understand' },
          { text: 'Help', onClick: () => navigate('/help'), isSecondary: true },
          { text: 'Withdraw', onClick: () => toggleSettingsMenu(reservation), isSecondary: true },
        ]}
      />
    </div>
  );
};

export default ReservationItem;
