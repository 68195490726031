import Api from '../components/Api';

const getCustomerSubscription = async (wp = false) => {
  const subscription = await Api(`/customer/subscription?wp=${wp}`, 'GET');

  if (subscription.status === 200) {
    const result = subscription.data;
    if (result.message === undefined) {
      if (result.success || result.userSubscriptions) {
        return result;
      } else {
        return result.subscription;
      }
    } else {
      return result;
    }
  } else {
    return null;
  }
};

export default getCustomerSubscription;
