import axios from 'axios';
import notifySwift from '../constants/notifySwift';
import renderToastError from '../renderFunctions/renderToastError';

const Api = async (uri, method, data, _, v = '/v1', external = false, formData = false) => {
  var url = uri;
  var headers = {
    'Content-Type': 'application/json',
  };

  const saveDeviceToken = localStorage.getItem('customDeviceToken');
  const accessToken = localStorage.getItem('accessToken');

  notifySwift({
    function: 'getUniqueDeviceID',
  });

  console.log('endpoint hit: ', uri);

  if (external === false) {
    url = process.env.REACT_APP_API_LIVE + v + uri;
    // url = process.env.REACT_APP_API_LOCAL + v + uri;

    if (method === 'get') {
      const keys = Object.keys(data);
      if (keys.length > 0) {
        url += '?';
        for (var k = 0; k < keys.length; k++) {
          url += keys[k] + '=' + encodeURIComponent(data[keys[k]]) + '&';
        }
        url = url.substring(0, url.length - 1);
        console.log('URL: ', url);
      }
    }

    headers = {
      'Content-Type': formData ? 'multipart/form-data' : 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    if (saveDeviceToken) {
      headers.xcdeviceid = saveDeviceToken;
    }
  }

  const config = {
    method: method,
    maxBodyLength: Infinity,
    url: url,
    headers: headers,
    data: data,
  };

  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    if (error?.response?.status === 401) {
      console.log('Unauthorized: Logging out user.');

      if (accessToken) {
        setTimeout(() => {
          renderToastError({
            toastId: 'session-expired',
            message: 'Your session has expired. Please log in again to continue.',
            autoClose: 2500,
            onClose: () => {
              logout();
            },
          });
        }, 200);
      } else {
        logout();
      }
    }

    return error;
  }
};

const logout = () => {
  localStorage.clear();
  notifySwift({
    function: 'logout',
    data: {},
  });
  window.location.href = '/login';
};

export default Api;
