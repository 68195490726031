import React, { useEffect, useCallback, useState } from 'react';
import splashBG from '../images/splash-bg.jpg';
import { useNavigate } from 'react-router-dom';
import { getCustomerActiveSession } from '../constants/getCustomerSession';
import { updateCustomerRequirements } from '../constants/updateCustomerRequirements';
import notifySwift from '../constants/notifySwift';
import allValuesTrue from '../constants/allValuesTrue';
import renderToastError from '../renderFunctions/renderToastError';
import CircularProgressBar from '../components/CircularProgressBar';
import * as Sentry from '@sentry/react';
import { useCustomer } from '../contexts/CustomerContext';

function Splash() {
  const navigate = useNavigate();
  const { customer } = useCustomer();
  const [showReload, setShowReload] = useState(false);

  const checkUser = useCallback(async () => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      navigate('/login');
    }

    if (customer) {
      if (customer.flaggedForDeletion) {
        localStorage.clear();
        renderToastError({
          message:
            'Your account has been flagged for deletion and an email has been sent with further information. You are unable to continue. If this is a mistake, please contact us.',
        });
        navigate('/login');
        return;
      }

      if (customer.initialComplete) {
        notifySwift({
          function: 'promptLocationPermissions',
        });
        notifySwift({
          function: 'promptBluetoothPermissions',
        });
        notifySwift({
          function: 'promptPushNotifications',
        });
      }

      const allTrue = customer?.requirements && allValuesTrue(customer.requirements);
      if (allTrue) {
        const session = await getCustomerActiveSession();
        if (session) {
          navigate(`/session?id=${session._id}`);
        } else {
          navigate('/your-sessions');
        }
      } else {
        navigate('/welcome');
      }
    }
  }, [navigate, customer]);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  useEffect(() => {
    window.addEventListener('LocationPermissions', (e) =>
      updateCustomerRequirements({
        type: 'location',
        data: e,
        retrieveCustomer: () => null,
      })
    );
    window.addEventListener('BluetoothPermissions', (e) =>
      updateCustomerRequirements({
        type: 'bluetooth',
        data: e,
        retrieveCustomer: () => null,
      })
    );
    window.addEventListener('PushNotifications', (e) =>
      updateCustomerRequirements({
        type: 'notifications',
        data: e,
        retrieveCustomer: () => null,
      })
    );

    return () => {
      window.removeEventListener('LocationPermissions', (e) =>
        updateCustomerRequirements({
          type: 'location',
          data: e,
          retrieveCustomer: () => null,
        })
      );
      window.removeEventListener('BluetoothPermissions', (e) =>
        updateCustomerRequirements({
          type: 'bluetooth',
          data: e,
          retrieveCustomer: () => null,
        })
      );
      window.removeEventListener('PushNotifications', (e) =>
        updateCustomerRequirements({
          type: 'notifications',
          data: e,
          retrieveCustomer: () => null,
        })
      );
    };
  }, []);

  useEffect(() => {
    const refreshTimeout = setTimeout(() => {
      Sentry.captureMessage('Failed to navigate away from splash screen');
      setShowReload(true);
    }, 10000);

    return () => clearTimeout(refreshTimeout);
  }, []);

  return (
    <div
      className="flex flex-col justify-end bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover"
      style={{ backgroundImage: `url(${splashBG})` }}
    >
      <div className="px-10 mb-40 space-y-3">
        {showReload ? (
          <>
            <p className="text-center text-sm">
              Failed to retrieve account details, press reload to try again or contact us for assistance.
            </p>
            <button className="w-full" onClick={async () => window.location.reload()}>
              Reload
            </button>
            <button
              className="w-full border border-white bg-transparent whiteShadow"
              onClick={async () => navigate('/logout')}
            >
              Logout
            </button>
          </>
        ) : (
          <CircularProgressBar />
        )}
      </div>
    </div>
  );
}

export default Splash;
