import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import Api from '../components/Api';

export const AppSettingsContext = createContext();

export const AppSettingsContextProvider = ({ children }) => {
  const [appSettings, setAppSettings] = useState(null);
  const [appSettingsLoading, setAppSettingsLoading] = useState(false);

  const notices = appSettings?.notices ?? [];

  const getAppSettings = useCallback(async () => {
    setAppSettingsLoading(true);
    const response = await Api('/appsettings', 'GET');
    if (response?.status === 200 && response.data?.appSettings) {
      setAppSettings(response.data.appSettings);
    }
    setAppSettingsLoading(false);
  }, []);

  return (
    <AppSettingsContext.Provider value={{ appSettings, notices, getAppSettings, appSettingsLoading }}>
      {children}
    </AppSettingsContext.Provider>
  );
};

export const useAppSettings = () => {
  const context = useContext(AppSettingsContext);

  if (!context) {
    throw new Error('useAppSettings must be used within a AppSettingsContextProvider');
  }

  const { getAppSettings } = context;

  useEffect(() => {
    getAppSettings();
  }, [getAppSettings]);

  return context;
};
