import React, { useState, useEffect, useRef } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import BackButton from '../components/BackButton';
import renderToastError from '../renderFunctions/renderToastError';
import CircularProgressBar from '../components/CircularProgressBar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import Api from '../components/Api';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import UpdateCardForm from '../components/UpdateCardForm';
import { useCustomer } from '../contexts/CustomerContext';

const CancelRequest = () => {
  const navigate = useNavigate();
  const { customerMembership, customerLoading } = useCustomer();
  const mainContainer = useRef();
  const [loading, setLoading] = useState(false);
  const [dateLoading, setDateLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({
    cancelDate: moment().toDate(),
    reason: '',
    cancelNow: true,
  });
  const [errors, setErrors] = useState({});
  const [futureRate, setFutureRate] = useState(null);
  const [cancellationLink, setCancellationLink] = useState('');
  const [reconsiderationLink, setReconsiderationLink] = useState('');
  const [renderUpdatePayment, setRenderUpdatePayment] = useState(false);
  const [renderUnableToCancel, setRenderUnableToCancel] = useState(false);
  const reasonRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectIsOpen, setSelectIsOpen] = useState(false); 
  const [customReason, setCustomReason] = useState("");


  const minDate = moment().add(1, 'days');

  const handleFormInputs = async (e, type) => {
    const val = e.target ? e.target.value : e;
    setFormDetails({ ...formDetails, [type]: val });

    if (type === 'reason') {
      if (val.length <= 9 && ( val !== "Other" && val !== "Unsatisfactory experience" )) {
        setErrors({ ...errors, [type]: 'Please provide a detailed response to help us improve our service.' });
        mainContainer.current?.scrollTo(0, mainContainer.current.scrollHeight);
        return;
      }
    } else if (type === 'cancelDate') {
      setDateLoading(true);
      const getCancelDetails = await Api('/customer/membership/cancel/details', 'POST', {
        cancelDate: moment(val).format('YYYY-MM-DD'),
      });
      
      console.log("cancelDetails: ", getCancelDetails);

      if (getCancelDetails.status === 200 && getCancelDetails.data.success) {
        setFutureRate(getCancelDetails.data.totalCancellationPrice);
        setReconsiderationLink(getCancelDetails.data.reconsiderationLink);
        setCancellationLink(getCancelDetails.data.cancellationLink);
      }
      setDateLoading(false);
    }

    if (errors[type]) setErrors({ ...errors, [type]: '' });
  };

  const validateForm = () => {
    const newErrors = {};
    for (const key in formDetails) {
      if (!formDetails[key]) {
        newErrors[key] = 'This field is required';
        reasonRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCancelSubmission = async () => {
    // if (!validateForm() || Object.values(errors).some((error) => error)) {
    //   renderToastError({ message: 'Please fill in all required fields.' });
    //   return;
    // }

    if (
      selectedOption === "" || 
      (["Other", "Unsatisfactory experience"].includes(selectedOption) && formDetails.reason.trim().length <= 9)
    ) {
      const errorMessage = selectedOption === "Other" || selectedOption === "Unsatisfactory experience" 
        ? 'Please provide a detailed response to help us improve our service.' 
        : 'Please select a reason before submitting your request.';
      
      renderToastError({ message: errorMessage });
      return;
    }

    setLoading(true);
    try {
      const cancel = await Api('/customer/membership/cancel', 'POST', {
        cancelDate: moment(formDetails.cancelDate).format('Do MMMM YYYY'),
        cancelReason: formDetails.reason,
        cancelNow: formDetails.cancelNow,
        cancellationLink,
        reconsiderationLink,
      });

      if (cancel.status === 200) {
        renderToastSuccess({
          message: cancel.data.message,
          onClose: () => navigate(-1),
        });
        setFormDetails({ cancelDate: moment().toDate(), cancelNow: true, reason: '' });
      } else if (cancel?.response?.status === 400) {
        const cancelResponse = cancel.response.data;
        renderToastError({ message: cancelResponse?.message || 'Request failed, please try again.' });
        setRenderUpdatePayment(
          cancelResponse?.xtraError === 1 || cancelResponse?.xtraError === 2 || cancelResponse?.xtraError === 3
        );
        setRenderUnableToCancel(!cancelResponse?.xtraError);
      }
    } catch {
      renderToastError({
        message: 'Request failed, please try again or contact us for assistance.',
      });
    }
    setLoading(false);
  };

  const getCancelDetails = async () => {
    const getCancelDetails = await Api('/customer/membership/cancel/details', 'POST', {
      cancelDate: moment().format('YYYY-MM-DD'),
    });
    if (getCancelDetails.status === 200 && getCancelDetails.data.success) {
      setReconsiderationLink(getCancelDetails.data.reconsiderationLink);
      setCancellationLink(getCancelDetails.data.cancellationLink);
    }
  };

  // Focus and scroll textarea into view when "Other" or "Unsatisfactory experience" is selected
  useEffect(() => {
    if (selectedOption === "Other" || selectedOption === "Unsatisfactory experience") {
      reasonRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      reasonRef.current?.focus();
    }
  }, [selectedOption]);

  // Scroll the textarea into view when focused
  const handleFocus = () => {
    setTimeout(() => {
      reasonRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }, 300); // Delay ensures smooth behavior after keyboard renders
  };

  useEffect(() => {
    getCancelDetails();
  }, []);

  const remainingDays = customerMembership?.nextPaymentDate
    ? moment(customerMembership.nextPaymentDate).diff(moment(), 'days')
    : 0;
  const additionalDays = 28 - remainingDays;

  const options = [
    "Unable to attend",
    "Not using enough",
    "Relocation",
    "Financial reasons",
    "Unsatisfactory experience",
    "Other",
  ];

  // Toggles the dropdown open/close state
  const toggleDropdown = () => {
    setSelectIsOpen((prevState) => {
      if (!prevState) {
        // Scroll to bottom when opening the dropdown
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }
      return !prevState;
    });
  };

 // Handles option selection and resets custom reason if not "Other"
 const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setSelectIsOpen(false);
    setCustomReason("");
    handleFormInputs("", "reason");
    if (option !== "Other" && option !== "Unsatisfactory experience") {
      console.log("option: ", option);
      handleFormInputs(option, "reason");
    }
  };

  function isNegative(num) {
    return num < 0;
  }

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-cover bg-center py-6 justify-between"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="flex flex-col">
        <SafeAreaHeader />
        <div className="flex flex-row items-center mb-6 mx-6">
          <BackButton />
          <h6>Cancel membership</h6>
        </div>
        {loading || customerLoading ? (
          <CircularProgressBar />
        ) : renderUnableToCancel ? (
          <div className="flex flex-col">
            <div className="py-3 px-5 flex flex-row gap-3 rounded-lg text-sm bg-xtraWhite20 mb-2.5 mx-6">
              <div className="flex justify-center items-center rounded-full bg-xtraError w-4 h-4">
                <FontAwesomeIcon icon={faExclamation} size="xs" className="text-white" />
              </div>
              <p className="flex-1 text-sm">
                We were unable to process your cancellation. Your request has been sent, and we will contact you
                shortly.
              </p>
            </div>
            <button onClick={() => navigate(-1)} className="ml-6 mr-6">
              Continue
            </button>
          </div>
        ) : renderUpdatePayment ? (
          <div className="p-5">
            <UpdateCardForm
              onSuccess={() => setRenderUpdatePayment(false)}
              onFailure={() => renderToastError({ message: 'Failed to update card. Please try again.' })}
            />
          </div>
        ) : customerMembership ? (
          <div className="flex flex-col gap-2.5 overflow-scroll max-h-[70vh]" ref={mainContainer}>
            <div className="py-3 px-5 flex flex-row gap-3 rounded-lg text-sm bg-xtraNavy30 mx-6">
              <div className="flex justify-center items-center rounded-full bg-xtraError w-4 h-4">
                <FontAwesomeIcon icon={faExclamation} size="xs" className="text-white" />
              </div>
              <p className="flex-1 text-sm">Please note: All memberships are bound by a 28 day cancellation policy.</p>
            </div>
            <div className='px-5'>
              <div className='flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3'>
                <div className='flex flex-col px-4 py-3'>
                  <p className='text-sm text-xtraWhite50'>Your next payment date</p>
                  <p className='font-bold text-2xl'>{moment(customerMembership?.nextPaymentDate).format('Do MMM YY')}</p>
                </div>
              </div>
              <div className='flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3'>
                <div className='flex flex-col px-4 py-3'>
                <p className='text-sm text-xtraWhite50'>Your next payment amount</p>
                <p className="font-bold text-2xl">{customerMembership?.nextPaymentAmount}</p>
                </div>
              </div>
              <div className='flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3'>
                <div className='flex flex-col px-4 py-3'>
                  <p className="text-sm text-xtraWhite50">Days remaining in credit</p>
                  <p className="font-bold text-2xl">{remainingDays}</p>
                </div>
              </div>
            </div>
            <div className="px-6">
              {/* <div className="tabs flex pb-3 mt-3">
                <button
                  className={`tab w-full focus:outline-none ${
                    formDetails.cancelNow ? 'text-white' : 'bg-gray-800 text-gray-300'
                  }`}
                  onClick={() => {
                    setFormDetails({
                      ...formDetails,
                      cancelNow: true,
                      cancelDate: moment().toDate(),
                    });
                    setFutureRate(null);
                  }}
                >
                  Cancel Now
                </button>
                <button
                  className={`tab w-full focus:outline-none ${
                    !formDetails.cancelNow ? 'text-white' : 'bg-gray-800 text-gray-300'
                  }`}
                  onClick={() =>
                    setFormDetails({
                      ...formDetails,
                      cancelNow: false,
                      cancelDate: '',
                    })
                  }
                >
                  Cancel Later
                </button>
              </div> */}
              <div className='flex flex-col my-3'>
                <p className='text-center font-bold text-lg'>
                  Cancellation Details
                </p>
              </div>
              {!formDetails.cancelNow && (
                <div className="flex-col w-full relative ">
                  <p className="text-sm">Cancellation Date</p>
                  <input
                    type="text"
                    className="w-full text-center mt-2 mb-4"
                    value={formDetails.cancelDate ? moment(formDetails.cancelDate).format('Do MMMM, YY') : ''}
                    placeholder="Select the date you would like to cancel"
                    readOnly
                    onClick={() => document.getElementById('cancel-datepicker').click()} // Show date picker popup on click
                  />
                  <DatePicker
                    id="cancel-datepicker"
                    selected={formDetails.cancelDate}
                    onChange={(e) => handleFormInputs(e, 'cancelDate')}
                    minDate={minDate.toDate()}
                    maxDate={moment(customerMembership?.nextPaymentDate).add(28, 'days').toDate()}
                    dateFormat="yyyy-MM-dd"
                    customInput={<div style={{ display: 'none', position: 'absolute' }} />} // Hide the actual date picker input
                    popperPlacement="bottom-start"
                    popperClassName="larger-datepicker-popper"
                  />
                  {errors.cancelDate && <div className="text-xtraError">{errors.cancelDate}</div>}
                </div>
              )}
              {dateLoading ? (
                <CircularProgressBar />
              ) : (
                (formDetails.cancelNow || formDetails.cancelDate) && (
                  <div className="mt-2.5 mb-4">
                      <div className='flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3'>
                        <div className='flex flex-col px-4 py-3'>
                          <p className="text-sm text-xtraWhite50">You will continue having access to the club until</p>
                          <p className="font-bold text-2xl mt-2">
                            {
                              moment(formDetails.cancelNow ? moment() : formDetails.cancelDate)
                              .add(28, 'days')
                              .format('Do MMMM, YY')
                            }
                          </p>
                        </div>
                      </div>
                      <div className='flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3'>
                        <div className='flex flex-col px-4 py-3'>
                          <p className="text-sm text-xtraWhite50">Days outstanding requiring payment to meet our 28 day cancellation policy</p>
                          <p className="font-bold text-2xl mt-2">{isNegative(additionalDays) ? 0 : additionalDays} days</p>
                        </div>
                      </div>
                      <div className='flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3'>
                        <div className='flex flex-col px-4 py-3'>
                          <p className="text-sm text-xtraWhite50">Your final payment to cover the remaining days. No further payments will be taken</p>
                          <p className="font-bold text-2xl mt-2">{isNegative(additionalDays) ? Number(0).toLocaleString('en-AU', {
                                style: 'currency',
                                currency: 'AUD',
                              }) : futureRate
                            ? Number(futureRate).toLocaleString('en-AU', {
                                style: 'currency',
                                currency: 'AUD',
                              })
                            : (customerMembership?.dailyRate * additionalDays).toLocaleString('en-AU', {
                                style: 'currency',
                                currency: 'AUD',
                              })}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        {/* Dropdown Trigger */}
                        <div
                          onClick={toggleDropdown}
                          className="flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3 w-full text-white px-4 py-3"
                        >
                          <span>{selectedOption ? selectedOption : "Select an option"}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`h-4 w-4 transform transition-transform ${
                              selectIsOpen ? "rotate-180" : "rotate-0"
                            }`}
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.2l3.71-3.97a.75.75 0 111.08 1.04l-4.24 4.53a.75.75 0 01-1.08 0L5.21 8.27a.75.75 0 01.02-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>

                        {/* Dropdown Options */}
                        {selectIsOpen && (
                          <div className="flex flex-col text-white bg-xtraNavy30 rounded items-left w-[90%] justify-center self-center">
                            {options.map((option, index) => (
                              <div
                                key={index}
                                onClick={() => handleOptionSelect(option)}
                                className="p-5 cursor-pointer border-b border-l border-r border-xtraWhite30"
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                        )}
                        {(selectedOption === "Other" || selectedOption === "Unsatisfactory experience") && (
                          <div className="mt-2 w-full">
                            <textarea
                              ref={reasonRef}
                              placeholder="Enter your reason for cancelling"
                              value={formDetails.reason}
                              onChange={(e) => handleFormInputs(e, 'reason')}
                              className="w-full text-left mt-2 mb-4 bg-xtraNavy30"
                              rows={3}
                              onFocus={handleFocus}
                            />
                            {errors.reason && <div className="text-xtraError">{errors.reason}</div>}
                          </div>
                        )}
                      </div>
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          <div className="px-5">
            <p>Sorry, your subscription details were not able to be retrieved. Please try again.</p>
          </div>
        )}
      </div>
      {customerMembership && !loading && (
        <div className="flex flex-col mb-10 px-5">
          <button className="w-full bg-xtraWhite10 border border-xtraError" onClick={handleCancelSubmission}>
            {'Submit cancellation request'}
          </button>
        </div>
      )}
    </div>
  );
};

export default CancelRequest;
