import React, { useState, useRef, useEffect, useCallback } from 'react';
import SafeAreaHeader from '../components/SafeAreaHeader';
import xtraBG from '../images/xtra-bg.jpg';
import towels from '../images/towel-square.png';
import fridge from '../images/fridge-square.png';
import lock from '../images/lockers-square.png';
import gate from '../images/turnstile.jpg';
import frontDoor from '../images/frontDoor.jpg';
import teaStation from '../images/tea-station.jpg';
import orangeWarning from '../images/orangeWarningMark.png';
import renderToastError from '../renderFunctions/renderToastError';
import CircularProgressBar from '../components/CircularProgressBar';
import { useNavigate, useLocation } from 'react-router-dom';
import Api from '../components/Api';
import TabBarNavigator from '../components/TabBarNavigator';
import { ReactComponent as PlanIcon } from '../assets/tabBar/Plan.svg';
import { ReactComponent as HelpIcon } from '../assets/tabBar/Help.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import notifySwift from '../constants/notifySwift';
import SlideUpDrawer from '../components/SlideUpDrawer';
import LockerSlideUpDrawer from '../components/SlideUpDrawer';
import LargeLockerSlideUpDrawer from '../components/SlideUpDrawer';
import ReassignLockerSlideUpDrawer from '../components/SlideUpDrawer';
import { getCustomerActiveSession } from '../constants/getCustomerSession';
import AccountIcon from '../components/AccountIcon';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import LockerDetailPopup from '../components/LockerDetailPopup';
import OverstayPopup from '../components/OverstayPopup';
import isCasual from '../constants/isCasual';
import { useCustomer } from '../contexts/CustomerContext';
import { useAppSettings } from '../contexts/AppSettingsContext';

const SessionOption = ({ image, title, description, handleClick }) => (
  <div className="bg-xtraNavy rounded-lg p-4 flex flex-row gap-2.5 items-center" onClick={handleClick}>
    <img src={image} className="h-20 w-20 rounded-lg object-cover" alt="option" />
    <div className="flex flex-1 flex-row justify-between items-center">
      <div className="flex flex-col justify-center gap-1 max-w-[70%]">
        <h5>{title}</h5>
        <p>{description}</p>
      </div>
      <div className="w-12 h-12 flex justify-center items-center bg-xtraAqua rounded-full text-center align-middle">
        <FontAwesomeIcon icon={faArrowRight} size="xl" className="text-white" />
      </div>
    </div>
  </div>
);

const Session = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { customer, customerLoading, customerEntitlements } = useCustomer();
  const { notices: appNotices } = useAppSettings();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('id');

  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [site, setSite] = useState(null);
  const [showSlideUp, setShowSlideUp] = useState(false);
  const [lockerDrawer, setLockerDrawer] = useState(false);
  const [lockerLargeDrawer, setLockerLargeDrawer] = useState(false);
  const [lockerReassignDrawer, setLockerReassignDrawer] = useState(false);
  const [lockerLargeReassignDrawer, setLockerLargeReassignDrawer] = useState(false);
  const [showOpenGate, setShowOpenGate] = useState(false);
  const [showOpenDoor, setShowOpenDoor] = useState(false);
  const [showContributeForTea, setShowContributeForTea] = useState(false);
  const [showLockerPopup, setShowLockerPopup] = useState(false);
  const [showOverstayPopup, setShowOverstayPopup] = useState(false);
  const [lockerDetails, setLockerDetails] = useState(null);
  const [lockerLoading, setLockerLoading] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [endSessionDrawer, setEndSessionDrawer] = useState(false);
  const activeIntervalRef = useRef(null);
  const timeRemainingIntervalRef = useRef(null);
  const [notices, setNotices] = useState([]);

  const handleEndSessionClick = async () => {
    if (isCasual) {
      setEndSessionDrawer(true);
    } else {
      clearInterval(timeRemainingIntervalRef.current);
      clearInterval(activeIntervalRef.current);

      navigate('/tap-end', {
        state: {
          session,
        },
      });
    }
  };

  const handleAssignLocker = async () => {
    setLoading(true);
    const locker = await Api('/locker/assign', 'POST', {
      session: session,
      site: site,
    });

    if (locker.status === 200) {
      let result = locker.data;
      if (result.success) {
        if (result.locker !== null) {
          setLockerDetails({
            locker_number: result.locker.lockerNumber,
            locker_code: result.locker.lockerCode,
            locker_type: result.locker.lockerType,
          });

          setShowLockerPopup(true);
        } else {
          renderToastError({
            message: 'Sorry, there are no lockers available in this session.',
          });
        }
      } else {
        renderToastError({
          message: result.message,
        });
      }
    } else {
      renderToastError({
        message:
          locker?.response?.data?.message ||
          'Failed to assign new locker, please try again or contact us for assistance',
      });
    }
    setLoading(false);
  };

  const handleRequestTallLocker = async () => {
    setLockerLoading(true);
    const locker = await Api('/locker/assign/large', 'POST', {
      session: session,
      site: site,
    });

    if (locker.status === 200) {
      let result = locker.data;
      if (result.success) {
        if (result.locker !== null) {
          setLockerDetails({
            locker_number: result.locker.lockerNumber,
            locker_code: result.locker.lockerCode,
            locker_type: result.locker.lockerType,
          });

          setLockerLoading(false);
          setShowLockerPopup(true);
        } else {
          setLockerDetails(null);
          renderToastError({
            message: 'Sorry, there are no lockers available in this session.',
          });
          setLockerLoading(false);
        }
      } else {
        renderToastError({
          message: result.message,
        });
        setLockerLoading(false);
      }
    } else {
      renderToastError({
        message:
          locker?.response?.data?.message ||
          'Failed to assign new locker, please try again or contact us for assistance',
      });
      setLockerLoading(false);
    }
  };

  const handleReAssignLocker = async (large = false) => {
    setLockerLoading(true);
    const locker = await Api('/locker/reassign', 'POST', {
      session: session,
      site: site,
      large: large,
    });

    if (locker.status === 200) {
      let result = locker.data;
      if (result.success) {
        if (result.locker !== null) {
          setLockerDetails({
            locker_number: result.locker.lockerNumber,
            locker_code: result.locker.lockerCode,
            locker_type: result.locker.lockerType,
          });

          setLockerLoading(false);
          setShowLockerPopup(true);
        } else {
          setLockerDetails(null);
          renderToastError({
            message: 'Sorry, there are no lockers available in this session.',
          });
          setLockerLoading(false);
        }
      } else {
        renderToastError({
          message: result.message,
        });
        setLockerLoading(false);
      }
    } else {
      renderToastError({
        message:
          locker?.response?.data?.message ||
          'Failed to assign new locker, please try again or contact us for assistance',
      });
      setLockerLoading(false);
    }
  };

  const endSession = async () => {
    const endSession = await Api(`/session/${sessionId}/end`, 'PUT');

    if (endSession.status === 200) {
      clearInterval(timeRemainingIntervalRef.current);
      clearInterval(activeIntervalRef.current);

      renderToastSuccess({
        message: 'You successfully ended your session. We hope to see you again soon.',
      });
      navigate('/your-sessions');
    } else {
      renderToastError({ message: endSession?.error?.message ?? 'Error ending session, please try again.' });
    }
    setLoading(false);
  };

  const handleOpenClick = async (type = 'gate') => {
    setLoading(true);
    const response = await Api(
      `/kisi/locks/${type === 'gate' ? site.doors.Turnstile : site.doors.FrontDoor}/unlock`,
      'GET'
    );
    if (response.status === 200) {
      let result = response.data;
      if (result.message) {
        if (result.message !== 'Unlocked!') {
          renderToastError({
            message: `There was an issue opening the ${type}, please try again or contact us for help.`,
          });
        } else {
          renderToastSuccess({
            message: `The ${type} has been opened!`,
          });
        }
      }
    } else {
      renderToastError({
        message: `There was an issue opening the ${type}, please try again or contact us.`,
      });
    }
    setLoading(false);
  };

  const handleTowelOptionClick = async () => {
    if (session?.towelCabinetOpened) {
      renderToastError({
        message:
          'Sorry, you are only able to open the towel cabinet once, please speak to our staff if you are having any difficulty.',
      });
    } else {
      navigate(customerEntitlements?.['Towel Service'] ? '/towel-access' : '/towel-service', {
        state: {
          site: site,
          sessionId: sessionId,
        },
      });
      clearInterval(activeIntervalRef.current);
    }
  };

  const handleContributeForTeaClick = async () => {
    setLoading(true);
    const response = await Api('/customer/purchase-tea', 'GET');

    if (response.status === 200 && response.data.success) {
      renderToastSuccess({
        message: `Thank you for your contribution!`,
      });
    } else {
      renderToastError({
        message: 'There was an issue completing purchase, please try again or contact us.',
      });
    }
    setShowContributeForTea(false);
    setLoading(false);
  };

  const calculateTimeRemaining = (estimatedEndTime) => {
    if (estimatedEndTime) {
      const now = moment();
      const endTime = moment(estimatedEndTime);
      const diff = moment.duration(endTime.diff(now));
      if (Math.round(Math.max(0, diff.asMinutes())) === 0) {
        clearInterval(timeRemainingIntervalRef.current);
      }
      return Math.round(Math.max(0, diff.asMinutes()));
    } else {
      return null;
    }
  };

  const getCustomerSession = useCallback(async () => {
    if (sessionId) {
      let getSession = await Api(`/session/${sessionId}`, 'GET', null);
      if (getSession.status === 200 && getSession.data.session) {
        let result = getSession.data;
        result.session.overstays = result.overstays;
        setSession(result.session);
        setSite(result.site);
        setLoading(false);

        if (result.locker) {
          setLockerDetails({
            locker_number: result.locker.lockerNumber,
            locker_code: result.locker.lockerCode,
            locker_type: result.locker.lockerType,
          });
        }

        const estimatedEndTime = result.session.estimatedEndTimeUTC;
        const newTimeRemaining = calculateTimeRemaining(estimatedEndTime);
        setTimeRemaining(newTimeRemaining);

        if (newTimeRemaining > 0) {
          const interval = setInterval(() => {
            const timeRem = calculateTimeRemaining(estimatedEndTime);
            if (timeRem <= 0) {
              clearInterval(timeRemainingIntervalRef.current);
            }
            setTimeRemaining(timeRem);
          }, 30000);
          timeRemainingIntervalRef.current = interval;
        }
      } else {
        renderToastError({
          message: getSession?.data?.message ?? 'There was an error retrieving active session.',
        });
        setLoading(false);
      }
    }
  }, [sessionId]);

  useEffect(() => {
    getCustomerSession();

    return () => {
      clearInterval(timeRemainingIntervalRef.current);
    };
  }, [getCustomerSession]);

  useEffect(() => {
    const checkActiveSession = async () => {
      const activeSession = await getCustomerActiveSession();
      if (!activeSession) {
        navigate(`/your-sessions`);
      }
    };

    checkActiveSession();

    const interval = setInterval(() => {
      checkActiveSession();
    }, 10000);
    activeIntervalRef.current = interval;

    return () => {
      clearInterval(activeIntervalRef.current);
    };
  }, [navigate]);

  useEffect(() => {
    if (!sessionId) {
      navigate('/splash');
    }
  }, [navigate, sessionId]);

  useEffect(() => {
    if (appNotices) {
      const updatedNotices = [...appNotices];
      if (site) {
        const currentDayName = moment().format('dddd');
        const today = site.openHours[currentDayName];
        const finish = today.finish;

        const startTime = moment(finish, 'HH:mm');
        const endTime = moment(startTime).add(90, 'minutes');
        const currentTime = moment();

        const isWithinTimeWindow = currentTime.isBetween(startTime, endTime);

        updatedNotices.push({
          description: `All guests are required to leave the club before ${endTime.format(
            'hh:mma'
          )}. Failure to do so will incur fees and penalties. Max penalty $1,000.`,
          disabled: !isWithinTimeWindow,
          title: 'Club Closing',
        });
      }
      setNotices(updatedNotices);
    }
  }, [appNotices, site]);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-cover bg-center p-6 overflow-y-auto pb-9"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex justify-between">
        <div className="flex flex-row items-center">
          {/* <BackButton /> */}
          <h3 className="mb-3">Your session</h3>
        </div>
        <AccountIcon yourDetails={customer} handleClick={() => navigate('/you')} noPhoto={false} />
      </div>
      {loading || customerLoading ? (
        <CircularProgressBar />
      ) : (
        <div className="flex flex-col gap-2.5 overflow-scroll max-h-[75vh] pb-5">
          {session && (
            <div className="p-5 flex flex-col gap-3 rounded-lg bg-xtraWhite20">
              <div className="flex flex-row justify-between items-center">
                <p>Started</p>
                <p className="font-bold">
                  {moment(session.startTimeUTC).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('h:mmA')}
                </p>
              </div>
              <div className="flex flex-row justify-between items-center">
                <p>Duration</p>
                <p className="font-bold">{session.duration} minutes</p>
              </div>
              <div className="flex flex-row justify-between items-center">
                <p>End</p>
                <p className="font-bold">
                  {moment(session.estimatedEndTimeUTC)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format('h:mmA')}
                </p>
              </div>
              <div className="flex flex-row justify-center">
                {timeRemaining !== null && timeRemaining <= 5 && (
                  <>
                    <img src={orangeWarning} alt="warning" className="object-fit w-5 h-5 mr-1" />
                    <p className="font-semibold text-sm">{`${timeRemaining} minutes left`}</p>
                  </>
                )}
              </div>
              <button className="redButton" onClick={handleEndSessionClick}>
                End session
              </button>
              <p
                onClick={() => {
                  setShowOverstayPopup(true);
                }}
                className="text-sm align-middle text-center"
              >
                <FontAwesomeIcon icon={faInfoCircle} /> You're welcome to stay longer (charges apply)
              </p>
            </div>
          )}
          {notices.map((notice, index) =>
            notice.disabled ? null : (
              <div key={index} className="bg-xtraNavy rounded-lg p-4 flex flex-col">
                <div className="flex-grow-0 flex flex-row relative rounded-3xl bg-xtraOrange py-0.5 justify-center text-center items-center max-w-24 mb-2">
                  <p className="text-sm font-bold items-center">Notice</p>
                </div>
                <h6>{notice.title}</h6>
                <p className="text-sm mt-1">{notice.description}</p>
              </div>
            )
          )}
          <SessionOption
            image={frontDoor}
            title="Door"
            description="Open the door"
            handleClick={() => setShowOpenDoor(true)}
          />
          <SessionOption
            image={gate}
            title="Gate"
            description="Open the gate"
            handleClick={() => setShowOpenGate(true)}
          />
          <SessionOption
            image={lock}
            title={lockerDetails !== null ? `Locker ${lockerDetails.locker_number}` : 'Lockers'}
            description={lockerDetails !== null ? `Press to reveal code` : 'Reserve a locker'}
            handleClick={() => {
              lockerDetails !== null ? setShowLockerPopup(true) : setLockerDrawer(true);
            }}
          />
          <SessionOption
            image={towels}
            title="Towels"
            description={'Open the towel cabinet'}
            handleClick={() => {
              handleTowelOptionClick();
            }}
          />
          {!isCasual && (
            <SessionOption
              image={fridge}
              title="Fridge"
              description="Buy a product"
              handleClick={() => {
                notifySwift({
                  function: 'presentQRScanner',
                });
              }}
            />
          )}
          <SessionOption
            image={teaStation}
            title="Tea Honesty System"
            description="Contribute $2"
            handleClick={() => setShowContributeForTea(true)}
          />
          {lockerDetails !== null && (
            <LockerDetailPopup
              details={lockerDetails}
              handleRequestTallLocker={() => {
                setShowLockerPopup(false);
                setLockerLargeDrawer(true);
              }}
              handleReAssignTallLocker={() => {
                setShowLockerPopup(false);
                setLockerLargeReassignDrawer(true);
              }}
              handleReAssignLocker={() => {
                setShowLockerPopup(false);
                setLockerReassignDrawer(true);
              }}
              loading={lockerLoading}
              showLockerPopup={showLockerPopup}
              setShowLockerPopup={setShowLockerPopup}
            />
          )}
          <LockerSlideUpDrawer
            setShow={setLockerDrawer}
            show={lockerDrawer}
            action={() => {
              handleAssignLocker();
            }}
            actionText={'Assign Locker'}
            actionHeading={`Reserve a locker`}
            message={`We will assign you a locker number and its code, would you like to continue?`}
            actionRightAway={true}
          />
          <LargeLockerSlideUpDrawer
            setShow={setLockerLargeDrawer}
            show={lockerLargeDrawer}
            action={() => {
              handleRequestTallLocker();
            }}
            actionText={'Request for Tall Locker'}
            actionHeading={`Re-Assign to a tall locker`}
            message={`We will try and re-assign you a tall locker, would you like to continue?`}
            actionRightAway={true}
          />
          <ReassignLockerSlideUpDrawer
            setShow={setLockerReassignDrawer}
            show={lockerReassignDrawer}
            action={() => {
              handleReAssignLocker();
            }}
            actionText={'Re-Assign Locker'}
            actionHeading={`Re-Assign locker`}
            message={`Has the locker you have been given too hard for you to reach? Or are there some items already in the provided locker? Try and re-assign to a new locker.`}
            actionRightAway={true}
          />
          <SlideUpDrawer
            setShow={setLockerLargeReassignDrawer}
            show={lockerLargeReassignDrawer}
            action={() => {
              handleReAssignLocker(true);
            }}
            actionText={'Re-Assign Tall Locker'}
            actionHeading={`Re-Assign tall locker`}
            message={`Has the locker you have been given too hard for you to reach? Or are there some items already in the provided locker? Try and re-assign to a new tall locker.`}
            actionRightAway={true}
          />
          <SlideUpDrawer
            setShow={setShowSlideUp}
            show={showSlideUp}
            action={handleEndSessionClick}
            actionText={'Try Again'}
            actionHeading={`Are you still in ${site?.name}`}
            message={`We detect that you are still inside the ${site?.name} location. Please try again to end session.`}
            actionRightAway={true}
          />
          <SlideUpDrawer
            setShow={setShowOpenGate}
            show={showOpenGate}
            action={handleOpenClick}
            actionText={'Open Gate'}
            actionHeading={`Open Gate`}
            message={`Do you need to open the gate to enter the club?`}
            actionRightAway={true}
          />
          <SlideUpDrawer
            setShow={setShowOpenDoor}
            show={showOpenDoor}
            action={() => handleOpenClick('door')}
            actionText={'Open Door'}
            actionHeading={`Open Door`}
            message={`Do you need to open the door to enter the club?`}
            actionRightAway={true}
          />
          <SlideUpDrawer
            setShow={setShowContributeForTea}
            show={showContributeForTea}
            action={handleContributeForTeaClick}
            actionText={`Contribute $2`}
            actionHeading="Tea Honesty System"
            message={`Help us continue to provide high-quality tea with a small contribution from time to time. Each tea costs us 50c, so each $2 payment covers 4 teas for you.`}
            actionRightAway={true}
          />
          {isCasual && session && (
            <SlideUpDrawer
              setShow={setEndSessionDrawer}
              show={endSessionDrawer}
              action={endSession}
              actionClass={'redButton'}
              actionText={'End Session'}
              actionHeading={
                Math.sign(moment(session.estimatedEndTimeUTC).diff(moment(), 'minutes')) === -1
                  ? `It's time to end your session`
                  : `Are you sure you want to end your session?`
              }
              message={
                Math.sign(moment(session.estimatedEndTimeUTC).diff(moment(), 'minutes')) === -1
                  ? `You are now ${Math.abs(
                      moment(session.estimatedEndTimeUTC).diff(moment(), 'minutes')
                    )} minutes past your inteded end time.`
                  : `You still have ${moment(session.estimatedEndTimeUTC).diff(
                      moment(),
                      'minutes'
                    )} minutes left until you are required to end your session. `
              }
              actionRightAway={true}
            />
          )}
          <OverstayPopup
            showOverstayPopup={showOverstayPopup}
            setShowOverstayPopup={setShowOverstayPopup}
            overstays={session?.overstays}
            handleEndSession={handleEndSessionClick}
          />
        </div>
      )}
      <TabBarNavigator
        tabs={[
          {
            name: 'Session',
            Icon: PlanIcon,
            route: `/session?id=${sessionId}`,
          },
          { name: 'Help', Icon: HelpIcon, route: `/help?id=${sessionId}` },
        ]}
        paddingHorizontal={80}
      />
    </div>
  );
};
export default Session;
