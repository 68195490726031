import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import renderToastError from './renderFunctions/renderToastError';

import Splash from './pages/Splash';
import LogIn from './pages/LogIn';
import CreateAccount from './pages/CreateAccount';
import Dash from './pages/Dash';
import NotFound from './pages/NotFound';
import DownloadApp from './pages/DownloadApp';

import Product from './pages/Product';
import Doors from './pages/Doors';
import You from './pages/You';
import YourSessions from './pages/YourSessions';
import Session from './pages/Session';
import Reserve from './pages/Reserve';
import ReservationConfirmed from './pages/ReservationConfirmed';
import ScheduleQueue from './pages/ScheduleQueue';
import MembershipDetails from './pages/MembershipDetails';
import YourDetails from './pages/YourDetails';
import BillingDetails from './pages/BillingDetails';
import Logout from './pages/Logout';
import ResetPassword from './pages/ResetPassword';
import Welcome from './pages/Welcome';
import OpenDoor from './pages/OpenDoor';
import MemberAgreement from './pages/MemberAgreement';
import TowelService from './pages/TowelService';
import TowelAccess from './pages/TowelAccess';
import ChangeMembership from './pages/ChangeMembership';
import PauseMembership from './pages/PauseMembership';
import { saveToken } from './constants/saveToken';
import Help from './pages/Help';
import InStoreHelp from './pages/InStoreHelp';
import Waitlist from './pages/Waitlist';
import XtraSessions from './pages/xtra/Sessions';
import CurrentSession from './pages/xtra/CurrentSession';
import CustomerSessionPack from './pages/CustomerSessionPacks';
import CustomerPastSessions from './pages/CustomerPastSessions';
import RetrieveLockerCode from './pages/RetrieveLockerCode';
import CancelRequest from './pages/CancelRequest';
import ReconsiderMembership from './pages/ReconsiderMembership';
import ChangePassword from './pages/ChangePassword';
import OverstayCharges from './pages/OverstayCharges';
import ProductNoAuth from './pages/ProductNoAuth';
import CasualOpenDoor from './pages/casual/OpenDoor';
import Api from './components/Api';
import * as Sentry from '@sentry/react';
import ClubSurvey from './pages/ClubSurvey';
import TapHelp from './pages/TapHelp';
import TapSuccess from './pages/TapSuccess';
import NotificationHandler from './components/NotificationHandler';
import { useAppSettings } from './contexts/AppSettingsContext';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({ maskAllInputs: false, maskAllText: false }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // 100%
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api.xtraclubs\.au/, /^https:\/\/xtraclubs\.au/],
  // Session Replay
  replaysSessionSampleRate: 0.1, //10%
  replaysOnErrorSampleRate: 1.0,
});

async function receiveDataFromSwift(dataString) {
  console.log('Receiving data: ', dataString);
  try {
    const data = JSON.parse(dataString);
    // console.log('Receiving data', data);
    if (data?.action) {
      if (data.action === 'Auth0Login') {
        localStorage.setItem('accessToken', data.data.accessToken);
        const savedAccessToken = localStorage.getItem('accessToken');
        if (savedAccessToken) {
          window.location.href = '/check-user';
        }
      } else if (data.action === 'Auth0-OnLoad-Saved-Credentials') {
        localStorage.setItem('accessToken', data.data.accessToken);

        const savedAccessToken = localStorage.getItem('accessToken');
        if (savedAccessToken) {
          window.location.href = '/check-user';
        }
      } else if (data.action === 'Auth0LoginWeb') {
        if (data.data.accessToken) {
          const currentAccessToken = localStorage.getItem('accessToken');

          let redirect = true;
          if (currentAccessToken) {
            if (currentAccessToken === data.data.accessToken) {
              return '';
            }

            const response = await Api(
              '/customer/auth0',
              'POST',
              {
                token: currentAccessToken,
              },
              currentAccessToken
            );

            if (response.data.success && response.data.customer) {
              redirect = false;
            }
          }

          localStorage.setItem('accessToken', data.data.accessToken);

          if (redirect) {
            window.location.href = '/check-user';
          } else {
            window.location.reload();
          }
        }
      } else if (data.action === 'userLocationUpdated') {
        const event = new CustomEvent('UserLocationUpdated', {
          detail: {
            data: {
              latitude: data.data.latitude.toFixed(10),
              longitude: data.data.longitude.toFixed(10),
            },
          },
        });
        window.dispatchEvent(event);
      } else if (data.action === 'Auth0LoginError') {
        renderToastError({
          message: (
            <div>
              {data.data.title}
              <br />
              <br />
              {data.data.message}
            </div>
          ),
        });
      } else if (data.action === 'checkLocationPermissionsResponse') {
        const event = new CustomEvent('LocationPermissions', {
          detail: { data: data.data },
        });
        window.dispatchEvent(event);
      } else if (data.action === 'checkBluetoothPermissionsResponse') {
        console.log('checkBluetoothPermissionsResponse', data.data);
        const event = new CustomEvent('BluetoothPermissions', {
          detail: { data: data.data },
        });
        window.dispatchEvent(event);
      } else if (data.action === 'checkPushNotifications') {
        const event = new CustomEvent('PushNotifications', {
          detail: { data: data.data },
        });
        window.dispatchEvent(event);
      } else if (data.action === 'usersPushNotificationTokenFailed') {
        if (
          data.data.token &&
          data.data.token === 'no valid “aps-environment” entitlement string found for application'
        ) {
          const event = new CustomEvent('PushNotifications', {
            detail: { data: { enabled: true } },
          });
          window.dispatchEvent(event);
        }
      } else if (data.action === 'usersPushNotificationTokenUpdated') {
        if (data.data.token) {
          saveToken({
            type: window.Android ? 'android' : 'ios',
            token: data.data.token,
          });
          const event = new CustomEvent('PushNotifications', {
            detail: { data: { enabled: true } },
          });
          window.dispatchEvent(event);
        }
      } else if (data.action === 'getUniqueDeviceIDResponse') {
        if (data.data.deviceID) {
          localStorage.setItem('customDeviceToken', data.data.deviceID);
        }
      } else if (data.action === 'scannedQRCode') {
        if (data.data.qr_value) {
          let value = data.data.qr_value.replace('https://openapp.xtraclubs.au', '');
          window.location.href = value;
        }
      } else if (data.action === 'capturedPhoto') {
        if (data.data.photo) {
          const event = new CustomEvent('CapturedPhoto', {
            detail: { data: { photo: data.data.photo } },
          });
          window.dispatchEvent(event);
        }
      } else if (data.action === 'receivedRemoteNotification') {
        console.log('ReceivedRemoteNotification: ', data);
        if (data.data.notification || (data.data.title && data.data.body)) {
          const notification = data.data.notification ?? { title: data.data.title, body: data.data.body };
          const event = new CustomEvent('ReceivedRemoteNotification', {
            detail: { data: { notification: notification } },
          });
          window.dispatchEvent(event);
        }
      } else if (data.action === 'tappedRemoteNotification') {
        console.log('TappedRemoteNotification: ', data);
        if (data.data.notification) {
          const notification = data.data.notification;
          const event = new CustomEvent('TappedRemoteNotification', {
            detail: { data: { notification: notification } },
          });
          window.dispatchEvent(event);
        }
      } else if (data.action === 'nativeError') {
        console.log('nativeError: ', data);
        if (data.data.message) {
          renderToastError({
            message: data.data.message,
          });
        }
      }
    }
  } catch (err) {
    console.error('Error parsing data from native', err);
  }

  return '';
}

window.receiveDataFromSwift = receiveDataFromSwift;

function App() {
  const [onboardingSteps, setOnboardingSteps] = useState([]);
  const { appSettings } = useAppSettings();

  useEffect(() => {
    if (appSettings) {
      const terms = appSettings?.membershipAgreement?.terms;
      const initialSteps = appSettings.clubSurvey.filter((step) => !step.disabled);

      const updatedOnboardingSteps = initialSteps.map((step) => ({
        ...step,
        terms: terms,
      }));

      setOnboardingSteps(updatedOnboardingSteps);
    }
  }, [appSettings]);

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<PagesList />} /> */}
        <Route path="/splash" element={<Splash />} />
        <Route path="/check-user" element={<Splash />} />
        <Route path="/login" element={<Dash />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/download-app" element={<DownloadApp />} />
        <Route path="/login-form" element={<LogIn />} />
        <Route path="/doors" element={<Doors />} />
        <Route path="/you" element={<You />} />
        <Route path="/your-sessions" element={<YourSessions />} />
        <Route path="/tap-door" element={<TapHelp key="openDoorTap" />} />
        <Route path="/open-door" element={<OpenDoor key="openDoor" />} />
        <Route path="/tap-gate" element={<TapHelp key="openGateTap" type="gate" />} />
        <Route path="/open-gate" element={<OpenDoor key="openGate" type="gate" />} />
        <Route path="/tap-start-success" element={<TapSuccess />} />
        <Route path="/session" element={<Session />} />
        <Route path="/tap-end" element={<TapHelp key="endTap" type="end" />} />
        <Route
          path="/tap-end-success"
          element={
            <TapSuccess
              title="Thank you!"
              message="Next time, no need to press end session - just tap your phone at the reader to end your session!"
            />
          }
        />
        <Route path="/reserve" element={<Reserve />} />
        <Route path="/reservation-confirmed" element={<ReservationConfirmed />} />
        <Route path="/schedule-queue" element={<ScheduleQueue />} />
        <Route path="/membership-details" element={<MembershipDetails />} />
        <Route path="/reconsider-membership" element={<ReconsiderMembership />} />
        <Route path="/customer-session-packs" element={<CustomerSessionPack />} />
        <Route path="/customer-past-sessions" element={<CustomerPastSessions />} />
        <Route path="/your-details" element={<YourDetails />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/billing-details" element={<BillingDetails />} />
        <Route path="/overstay-charges" element={<OverstayCharges />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/waitlist" element={<Waitlist />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/permissions" element={<Welcome isPermissionsRoute />} />
        <Route path="/member-agreement" element={<MemberAgreement />} />
        <Route path="/towel-service" element={<TowelService />} />
        <Route path="/towel-access" element={<TowelAccess />} />
        <Route path="/fridge-product" element={<Product />} />
        <Route path="/change-membership" element={<ChangeMembership />} />
        <Route path="/pause-membership" element={<PauseMembership />} />
        <Route path="/cancel-membership" element={<CancelRequest />} />
        <Route path="/help" element={<Help />} />
        <Route path="/instore-help" element={<InStoreHelp />} />
        <Route path="/retrieve-locker-code" element={<RetrieveLockerCode />} />
        <Route path="/product-no-auth" element={<ProductNoAuth />} />
        <Route path="/xtra/sessions" element={<XtraSessions />} />
        <Route path="/xtra/session" element={<CurrentSession />} />
        <Route path="/casual/open-door" element={<CasualOpenDoor />} />
        {onboardingSteps.map((step, index) => {
          const stepNumber = index + 1;
          return (
            <Route
              key={index}
              path={`/member-onboarding/${stepNumber}`}
              element={
                <ClubSurvey
                  key={index}
                  title={`${step.title}`}
                  terms={step.terms}
                  confirmations={step.confirmations}
                  nextStep={stepNumber === onboardingSteps.length ? '/welcome' : `/member-onboarding/${stepNumber + 1}`}
                  stepNumber={stepNumber}
                />
              }
            />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer newestOnTop />
      <NotificationHandler />
    </BrowserRouter>
  );
}

export default App;
