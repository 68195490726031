import React, { useState, useEffect } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import BackButton from '../components/BackButton';
import renderToastError from '../renderFunctions/renderToastError';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import getMembershipPricing from '../constants/membershipPricing';
import CircularProgressBar from '../components/CircularProgressBar';
import updateMembership from '../constants/updateMembership';
import { useNavigate } from 'react-router-dom';
import SlideUpDrawer from '../components/SlideUpDrawer';
import ErrorSlideUpDrawer from '../components/SlideUpDrawer';
import { useCustomer } from '../contexts/CustomerContext';
import UpdateCardForm from '../components/UpdateCardForm';

const MembershipOption = ({ membership, selectedMembershipName, currentMembership, handleClick }) => {
  const isSelected = membership.title === selectedMembershipName;
  const isCurrent = membership.title === currentMembership.product_name;
  const currentPrice = isCurrent ? currentMembership.weekly_price : membership?.pricing?.weekly?.currentPrice;
  const originalPrice = membership?.pricing?.weekly?.originalPrice;
  const fullPrice = membership?.pricing?.weekly?.fullPrice;
  const isDiscounted = originalPrice !== fullPrice && (!isCurrent || currentPrice < originalPrice);
  return (
    <div className="p-5 flex flex-col rounded-lg bg-xtraWhite20" onClick={handleClick}>
      <div className="flex flex-row justify-between mb-5">
        <div className="flex flex-col">
          <h6>{membership.title}</h6>
          {isDiscounted && <p className="text-xs line-through decoration-red-500">${originalPrice}</p>}
          <p className="text-sm">${currentPrice}</p>
        </div>
        <h6
          className={`rounded-2xl w-20 h-8 flex justify-center items-center text-sm ${
            isSelected ? 'bg-xtraAqua' : 'border border-white '
          }`}
        >
          {isSelected ? 'Selected' : 'Select'}
        </h6>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <p className="text-sm">Peak sessions</p>
          <h6 className="text-base">{membership.peakSessions}</h6>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-sm">Off-peak sessions</p>
          <h6 className="text-base">{membership.offPeakSessions}</h6>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-sm">Session duration</p>
          <h6 className="text-base">{membership.sessionDuration}</h6>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-sm">All location access</p>
          <h6 className="text-base">{membership.allLocationAccess}</h6>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-sm">Towel service</p>
          <h6 className="text-base">{membership.towelService}</h6>
        </div>
      </div>
    </div>
  );
};

const ChangeMembership = () => {
  const navigate = useNavigate();
  const { customerMembershipProduct, customerMembershipProductName, customerLoading } = useCustomer();
  const [popOverMessage, setPopupMessage] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedMembershipName, setSelectedMembershipName] = useState('');
  const [errorSlideUp, setErrorSlideUp] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [essentialsMembership, setEssentialsMembership] = useState();
  const [elevateMembership, setElevateMembership] = useState();
  const [thriveMembership, setThriveMembership] = useState();
  const [loading, setLoading] = useState(true);

  const handleSelectMembership = async (membership) => {
    setSelectedMembershipName(membership.title);
  };

  const handleUpdatePaymentMethod = () => {
    setShowCardForm(true); // Show the Stripe form instead of redirecting
  };

  const handleInitialContinueClick = async () => {
    setLoading(true);
    let message = await updateMembership(selectedMembershipName, 1);
    if (message?.message) {
      setPopupMessage(message.message);
      setShowDrawer(true);
    } else {
      renderToastError({
        message: 'Unable to update your membership right now, please try again later or contact us.',
      });
    }
    setLoading(false);
  };

  const handleContinueClick = async () => {
    setShowDrawer(false);
    setLoading(true);
    const membership = await updateMembership(selectedMembershipName, 0);
    if (membership !== null) {
      if (membership.success) {
        renderToastSuccess({
          message: membership.message,
          onClose: () => {
            navigate('/membership-details');
          },
        });
      } else {
        const message = membership?.response?.data?.message || membership.message;

        if (membership?.response?.data?.response?.decline_code !== 'insufficient_funds') {
          setErrorSlideUp(true);
        }
        renderToastError({ message });
      }
    } else {
      renderToastError({
        message: 'Unable to update your membership right now, please try again later or contact us.',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const retrieveMembershipPrices = async () => {
      let membershipPricing = await getMembershipPricing();
      if (membershipPricing?.success) {
        setEssentialsMembership({
          title: 'Essentials',
          peakSessions: '1',
          offPeakSessions: 'Unlimited',
          sessionDuration: '70 minutes',
          allLocationAccess: 'Not included',
          towelService: 'Not included',
          pricing: membershipPricing.tiers.Essentials,
        });
        setElevateMembership({
          title: 'Elevate',
          peakSessions: '3',
          offPeakSessions: 'Unlimited',
          sessionDuration: '100 minutes',
          allLocationAccess: 'Included',
          towelService: 'Not included',
          pricing: membershipPricing.tiers.Elevate,
        });
        setThriveMembership({
          title: 'Thrive',
          peakSessions: 'Unlimited',
          offPeakSessions: 'Unlimited',
          sessionDuration: 'Unlimited',
          allLocationAccess: 'Included',
          towelService: 'Included',
          pricing: membershipPricing.tiers.Thrive,
        });
      }
      setLoading(false);
    };

    retrieveMembershipPrices();
  }, []);

  useEffect(() => {
    if (customerMembershipProductName) {
      setSelectedMembershipName(customerMembershipProductName);
    }
  }, [customerMembershipProductName]);

  const continueDisabled = selectedMembershipName === customerMembershipProductName;

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover py-6"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-row items-center mb-6 mx-6">
        <BackButton />
        <h6>{'Change membership'}</h6>
      </div>
      {loading || customerLoading ? (
        <CircularProgressBar />
      ) : showCardForm ? (
        <div className="p-5">
          <UpdateCardForm onSuccess={() => setShowCardForm(false)} onFailure={() => setShowCardForm(false)} />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-2.5 px-6 overflow-scroll max-h-[70vh]">
            {essentialsMembership && (
              <MembershipOption
                membership={essentialsMembership}
                selectedMembershipName={selectedMembershipName}
                currentMembership={customerMembershipProduct}
                handleClick={() => handleSelectMembership(essentialsMembership)}
              />
            )}
            {elevateMembership && (
              <MembershipOption
                membership={elevateMembership}
                selectedMembershipName={selectedMembershipName}
                currentMembership={customerMembershipProduct}
                handleClick={() => handleSelectMembership(elevateMembership)}
              />
            )}
            {thriveMembership && (
              <MembershipOption
                membership={thriveMembership}
                selectedMembershipName={selectedMembershipName}
                currentMembership={customerMembershipProduct}
                handleClick={() => handleSelectMembership(thriveMembership)}
              />
            )}
          </div>
          <div className="fixed bottom-14 left-0 right-0 z-10 px-6">
            <button
              disabled={continueDisabled}
              style={{ opacity: continueDisabled ? 0.5 : 1 }}
              className="w-full"
              onClick={handleInitialContinueClick}
            >
              Continue
            </button>
          </div>
          <SlideUpDrawer
            show={showDrawer}
            setShow={setShowDrawer}
            action={handleContinueClick}
            actionText="Update Membership"
            actionHeading="Change Membership?"
            message={popOverMessage}
          />
          <ErrorSlideUpDrawer
            setShow={setErrorSlideUp}
            show={errorSlideUp}
            action={handleUpdatePaymentMethod} // Trigger the form
            actionText="Update Card Details"
            actionHeading="Update Card Details"
            message="Looks like your payment did not go through successfully. Please update your card details to proceed."
            actionRightAway={true}
          />
        </>
      )}
    </div>
  );
};

export default ChangeMembership;
