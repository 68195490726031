import React from 'react';
import { components } from 'react-select';

const CustomOption = ({ children, ...props }) => {
  // Extracting the <p> tag with class 'optionHeading'
  var optionHeading = '';
  React.Children.toArray(children).find((child) => {
    return React.Children.toArray(child.props.children).find((secondChild) => {
      return React.Children.toArray(secondChild.props.children).find((thirdChild) => {
        if (thirdChild.props.className && thirdChild.props.className.includes('optionHeading')) {
          optionHeading = `${thirdChild.props.children[0]}`.replace(/\bSession\b/g, "Session Pack").replace(/\bSessions\b/g, "Session Pack");
        }
        return optionHeading;
      });
    });
  });

  return (
    <components.Option {...props}>
      <span className="text-white" dangerouslySetInnerHTML={{ __html: optionHeading }}></span>
    </components.Option>
  );
};

export default CustomOption;
