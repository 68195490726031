import React, { useState } from 'react';
import greenTick from '../images/green-tick.png';
import redCross from '../images/red-cross.png';
import SharePopup from '../components/SharePopup';
import CircularProgressBar from '../components/CircularProgressBar';
import moment from 'moment-timezone';
import ShareIcon from "../images/shareIcon.png";

const StringListWithTicks = ({ items, reservations }) => {
  const [displayShare, setDisPlayShare] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const totalDuration = 5; // Total duration for all items to fade in (in seconds)
  const itemDuration = totalDuration / items.length; // Duration for each item to fade in
  const renderStatusTick = (status) => {
    var tick = null;
    switch (status) {
      case 'Success':
        tick = <img src={greenTick} alt="Tick" className="mr-1 w-4" />;
        break;
      case 'Withdrawn':
        tick = <img src={redCross} alt="Cross" className="mr-1 w-4" />;
        break;
      case 'Error':
        tick = <img src={redCross} alt="Cross" className="mr-1 w-4" />;
        break;
      case 'Session Full':
        tick = <img src={redCross} alt="Cross" className="mr-1 w-4" />;
        break;
      default:
        tick = <CircularProgressBar width={40} height={40} marginTop="" />;
        break;
    }
    return tick;
  };

  const shareReserve = async (index) => {
    console.log("item: ", index);
    let time = moment(reservations[index].date + ' ' + reservations[index].time, 'YYYY-MM-DD H:mm').format('h:mma')
    let date = moment(reservations[index].date).isSame(moment(), 'day') ? "today" : "on " + moment(reservations[index].date, 'YYYY-MM-DD').format("Do MMM YY");

    if (navigator.share) {
      try {
        ;
        await navigator.share({
          title: "Join XtraClubs",
          text: `I've booked in at XtraClubs for a sauna, ice and steam session ${date} at ${time}. Book in here to join me 👉 https://xtraclubs.au/book-session`,
        });
        alert("Content shared successfully!");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.warn("Web Share API is not supported in your browser.");
      setDisPlayShare(true);
      setSelectedItem(reservations[index]);
    }
  }


  return (
    <div>
      {items.map((item, index) => (
        <div
          key={index}
          className="flex flex-row items-start mb-2 fade-in-item"
          style={{ animationDelay: `${index * itemDuration}s` }}
        >
          <div className='mt-3'>
            {renderStatusTick(item.status)}
          </div>
          <div className="flex flex-col pr-2">
            <p className="text-sm ml-2 font-bold">{item.text}</p>
            {item.status !== 'Success' && item.status !== 'Queued' && (
              <p className="text-sm font-bold mt-1 ml-2 rounded py-1 px-4 bg-xtraWhite30 w-fit">{item.status}</p>
            )}
            {
              item.status === "Success" && (
                <button onClick={() => shareReserve(index)} className='bg-transparent flex flex-row justify-center items-center border-2 border-[#3DAED4] p-2 my-2'>
                  <img src={ShareIcon} className='w-4 mr-2'/>
                  <p className='font-bold text-sm'>Invite a Friend</p>
                </button>
              )
            }
          </div>
        </div>
      ))}
      {
        displayShare && (
          <SharePopup 
            date={moment(selectedItem.date).isSame(moment(), 'day') ? "today" : "on " + moment(selectedItem.date, 'YYYY-MM-DD').format("Do MMM YY")} 
            time={moment(selectedItem.date + ' ' + selectedItem.time, 'YYYY-MM-DD H:mm').format('h:mma')} 
            modalIsOpen={displayShare}
            closeModal={() => setDisPlayShare(false)}
          />
        )
      }
    </div>
  );
};

export default StringListWithTicks;
