import React from "react";
import Modal from "react-modal";
import {
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";

import renderToastSuccess from "../renderFunctions/renderToastSuccess";
import renderToastError from "../renderFunctions/renderToastError";
import notifySwift from "../constants/notifySwift";

const SharePopup = ({ date, time, modalIsOpen, closeModal }) => {
  const shareUrl = "https://xtraclubs.au/book-session"; // Replace with your URL
  const shareText = `I've booked in at XtraClubs for a sauna, ice, and steam session ${date} at ${time}. Join me by booking a session.`;

  // Custom sharing handlers
  const openFacebook = () => {
    const url = encodeURIComponent(shareUrl);
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    try {
      notifySwift({
        function: 'openInternalLink',
        data: {
            url: facebookUrl,
        },
    });
      renderToastSuccess({ message: "Opening Facebook for sharing..." });
    } catch (err) {
      console.error("Failed to open Facebook:", err);
      renderToastError({ message: "Failed to open Facebook for sharing." });
    }
  };

  const openWhatsApp = () => {
    const message = encodeURIComponent(shareText);
    const whatsappUrl = `https://wa.me/?text=${message}`;
    try {
        notifySwift({
            function: 'openInternalLink',
            data: {
                url: whatsappUrl,
            },
        });
        renderToastSuccess({ message: "Opening WhatsApp for sharing..." });
    } catch (err) {
      console.error("Failed to open WhatsApp:", err);
      renderToastError({ message: "Failed to open WhatsApp for sharing." });
    }
  };

  const openEmail = () => {
    const subject = encodeURIComponent("Check this out!");
    const body = encodeURIComponent(`${shareText} ${shareUrl}`);
    const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;
    try {
        notifySwift({
            function: 'openInternalLink',
            data: {
                url: mailtoUrl,
            },
        });
      renderToastSuccess({ message: "Opening email client for sharing..." });
    } catch (err) {
      console.error("Failed to open email client:", err);
      renderToastError({ message: "Failed to open email client for sharing." });
    }
  };

  const copyToClipboard = () => {
    const textToCopy = `${shareText} ${shareUrl}`;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          renderToastSuccess({ message: "Link copied to clipboard!" });
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
          renderToastError({ message: "Failed to copy text to clipboard." });
        });
    } else {
      fallbackCopyToClipboard(textToCopy);
    }
  };

  const fallbackCopyToClipboard = (text) => {
    try {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.setAttribute("readonly", true);
      textArea.style.position = "fixed";
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      textArea.setSelectionRange(0, 99999); // For mobile devices

      const successful = document.execCommand("copy");
      if (successful) {
        renderToastSuccess({ message: "Link copied to clipboard!" });
      } else {
        renderToastError({ message: "Unable to copy link. Please copy manually." });
      }
    } catch (err) {
      console.error("Fallback error copying text:", err);
      renderToastError({ message: "Failed to copy link. Please copy manually." });
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        style={{
          content: {
            maxWidth: "400px",
            margin: "auto",
            padding: "20px",
            textAlign: "center",
            backgroundColor: "#002126",
            height: "30vh",
            overflow: "hidden",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <h4 style={{ color: "#ffffff" }}>Share with your friends</h4>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            margin: "20px 0",
          }}
        >
          {/* Facebook Button */}
          <div
            onClick={openFacebook}
            style={{ cursor: "pointer" }}
          >
            <FacebookIcon size={40} round />
          </div>

          {/* WhatsApp Button */}
          <div
            onClick={openWhatsApp}
            style={{ cursor: "pointer" }}
          >
            <WhatsappIcon size={40} round />
          </div>

          {/* Email Button */}
          <div
            onClick={openEmail}
            style={{ cursor: "pointer" }}
          >
            <EmailIcon size={40} round />
          </div>
        </div>

        {/* Copy to Clipboard Button */}
        <button
          onClick={copyToClipboard}
          style={{
            padding: "10px 20px",
            backgroundColor: "#28a745",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginBottom: "10px",
          }}
        >
          Copy Link
        </button>

        <button
          onClick={closeModal}
          style={{
            padding: "10px 20px",
            backgroundColor: "#dc3545",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Close
        </button>
      </Modal>
    </div>
  );
};

export default SharePopup;
