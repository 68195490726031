import React, { useState, useEffect } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import BackButton from '../components/BackButton';
import renderToastError from '../renderFunctions/renderToastError';
import CircularProgressBar from '../components/CircularProgressBar';
import updateCustomerTowelService from '../constants/customerTowelService';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import SlideUpDrawer from '../components/SlideUpDrawer';
import UpdateCardForm from '../components/UpdateCardForm'; // Import UpdateCardForm
import updateCustomerSessionDuration from '../constants/customerSessionDuration';
import { useNavigate } from 'react-router';
import updateCustomerPeakSession from '../constants/customerPeakSession';
import ErrorSlideUpDrawer from '../components/SlideUpDrawer';
import RestartSlideUpDrawer from '../components/SlideUpDrawer';
import moment from 'moment-timezone';
import Api from '../components/Api';
import getPurchasedPeakSessions from '../constants/getPurchasedPeakSessions';
import PeakSessionsDrawer from '../components/PeakSessionDrawer';
import handlePurchaseSinglePeakVisit from '../constants/handlePurchaseSinglePeakVisit';
import { useCustomer } from '../contexts/CustomerContext';
import alertIcon from '../images/alert.png';

const MembershipDetails = () => {
  const navigate = useNavigate();
  const {
    getCustomer,
    customerLoading,
    customerSubscription,
    customerEntitlements,
    customerMembership,
    customerMembershipProductName,
    customerMembershipHasExtendedSession,
    customerMembershipProduct,
  } = useCustomer({ update: true });
  const [peakSessionPurchased, setPeakSessionPurchased] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [errorSlideUp, setErrorSlideUp] = useState(false);
  const [peakSessionDrawer, showPeakSessionDrawer] = useState(false);
  const [displaySinglePeakVisitPurchase, setDisplaySinglePeakVisitPurchase] = useState(false);
  const [singlePeakVisitInfo, setSinglePeakVisitInfo] = useState(null);
  const [showRestartDrawer, setShowRestartDrawer] = useState(false);
  const [popupMessage, setPopupMessage] = useState();
  const [serviceLoading, setServiceLoading] = useState({
    towelService: false,
    sessionDuration: false,
    membership: false,
    peakSession: false,
  });
  const [peakVisitLoading, setPeakVisitLoading] = useState(false);
  const [activePause, setActivePause] = useState(null);
  const [activeFail, setActiveFail] = useState(null);
  const [failureReasons, setFailureReasons] = useState([]);
  const [showCardForm, setShowCardForm] = useState(false); // State to display UpdateCardForm

  const isThrive = customerMembershipProductName === 'Thrive';

  const activeMembership =
    customerMembership && customerMembershipProduct ? { ...customerMembership, ...customerMembershipProduct } : null;
  const getActivePause = async () => {
    const response = await Api('/customer/membership/pause/active', 'GET', null);
    console.log('response: ', response.data);
    if (response.status === 200 && response.data.pause) {
      setActivePause(response.data.pause);
    }
  };

  const getFaledOrders = async () => {
    const response = await Api('/customer/membership/orders/failed', 'GET', null);
    console.log('response: ', response.data);
    if (response.status === 200 && response.data.failed) {
      setActiveFail(response.data.failed);
      var records = response.data.failed;
      const allFailureReasons = records.flatMap((record) => record.failure_reasons);
      setFailureReasons(allFailureReasons);
    }
    setLoading(false);
  };

  const getPeakSessions = async () => {
    const purchasedPeakSessions = await getPurchasedPeakSessions(null);
    setPeakSessionPurchased(purchasedPeakSessions);
  };

  const getTowelServiceMessage = async () => {
    setPopupMessage(null);
    const enable = customerEntitlements?.['Towel Service'];
    let updateTowel = await updateCustomerTowelService(!enable, 1);
    if (updateTowel) {
      if (updateTowel.success && updateTowel.message) {
        console.log('updateTowel', updateTowel);
        setPopupMessage(updateTowel.message);
      }
    }
  };

  const getSessionDurationMessage = async (enable) => {
    setPopupMessage(null);
    let updateDuration = await updateCustomerSessionDuration(!enable, 1);
    if (updateDuration) {
      if (updateDuration.success && updateDuration.message) {
        setPopupMessage(updateDuration.message);
      }
    }
  };

  const getPeakSessionMessage = async (type) => {
    setPopupMessage(null);
    let peakSession = await updateCustomerPeakSession(1, type);
    if (peakSession) {
      if (peakSession.success && peakSession.message) {
        setPopupMessage(peakSession.message);
      }
    }
  };

  const updateTowelService = async () => {
    setServiceLoading({
      ...serviceLoading,
      towelService: true,
    });
    const enable = customerEntitlements?.['Towel Service'];
    let updateTowel = await updateCustomerTowelService(!enable, 0);
    if (updateTowel) {
      console.log('update towel ', updateTowel);
      if (updateTowel.success && updateTowel.message) {
        getCustomer({ update: true });
        renderToastSuccess({
          toastId: 'CustomerSubscriptionError',
          message: updateTowel.message,
          autoClose: false,
        });
      } else {
        let message = updateTowel?.response?.data?.message ? updateTowel.response.data.message : updateTowel.message;

        if (updateTowel?.response?.data?.response?.decline_code !== 'insufficient_funds') {
          setErrorSlideUp(true);
        }
        renderToastError({
          message: message,
        });
      }
    } else {
      renderToastError({
        message: 'Failed to add the towel service to your account, please try again or contact us.',
      });
      setServiceLoading({
        ...serviceLoading,
        towelService: false,
      });
    }
    setServiceLoading({
      ...serviceLoading,
      towelService: false,
    });
  };

  const updateSessionDuration = async () => {
    setServiceLoading({
      ...serviceLoading,
      sessionDuration: true,
    });
    const enable = !customerMembershipHasExtendedSession;
    console.log('enable: ', enable);
    let updateDuration = await updateCustomerSessionDuration(enable, 0);
    if (updateDuration) {
      console.log('update session duration ', updateDuration);
      if (updateDuration.success && updateDuration.message) {
        getCustomer({ update: true });
        renderToastSuccess({
          toastId: 'CustomerSubscriptionError',
          message: updateDuration.message,
          autoClose: false,
        });
      } else {
        let message = updateDuration?.response?.data?.message
          ? updateDuration.response.data.message
          : updateDuration.message;

        if (updateDuration?.response?.data?.response?.decline_code !== 'insufficient_funds') {
          setErrorSlideUp(true);
        }
        renderToastError({
          message: message,
        });
      }
    } else {
      renderToastError({
        message: 'Failed to add the towel service to your account, please try again or contact us.',
      });
      setServiceLoading({
        ...serviceLoading,
        sessionDuration: false,
      });
    }
    setServiceLoading({
      ...serviceLoading,
      sessionDuration: false,
    });
  };

  const updatePeakSession = async (type) => {
    setServiceLoading({
      ...serviceLoading,
      peakSession: true,
    });
    let updatePeakSession = await updateCustomerPeakSession(0, type);
    if (updatePeakSession) {
      console.log('update peak session ', updatePeakSession);
      if (updatePeakSession.success && updatePeakSession.message) {
        getCustomer({ update: true });
        renderToastSuccess({
          message: updatePeakSession.message,
          autoClose: false,
        });
      } else {
        let message = updatePeakSession?.response?.data?.message
          ? updatePeakSession.response.data.message
          : updatePeakSession.message;

        if (updatePeakSession?.response?.data?.response?.decline_code !== 'insufficient_funds') {
          setErrorSlideUp(true);
        }
        renderToastError({
          message: message,
        });
      }
    } else {
      renderToastError({
        message: 'Failed to add the towel service to your account, please try again or contact us.',
      });
      setServiceLoading({
        ...serviceLoading,
        peakSession: false,
      });
    }
    setServiceLoading({
      ...serviceLoading,
      peakSession: false,
    });
  };

  const handleSessionChangeClick = () => {
    getSessionDurationMessage(customerMembershipHasExtendedSession);
    setSelectedOption({
      action: updateSessionDuration,
      text: customerMembershipHasExtendedSession ? 'Shorten session duration' : 'Extend session duration',
      class: '',
    });
    setShowDrawer(true);
  };

  const handleTowelChangeClick = () => {
    getTowelServiceMessage();
    setSelectedOption({
      action: updateTowelService,
      text: customerEntitlements?.['Towel Service'] ? 'Remove Towel Service' : 'Add Towel Service',
      class: '',
    });
    setShowDrawer(true);
  };

  const handlePeakSessionClick = (type) => {
    showPeakSessionDrawer(false);
    getPeakSessionMessage(type);
    setSelectedOption({
      action: () => updatePeakSession(type),
      text: type ? 'Decrease Peak Sessions' : 'Increase Peak Sessions',
      class: '',
    });
    setShowDrawer(true);
  };

  const restartMembership = async () => {
    setLoading(true);
    const restart = await Api('/customer/membership/restart', 'GET');
    if (restart.status === 200) {
      let result = restart.data;
      if (result.success) {
        renderToastSuccess({
          message: result.message,
        });
      } else {
        renderToastError({
          message: result.message,
        });
      }
    } else {
      renderToastError({
        message: 'Unable to restart your subscription. Please contact our support for further instructions.',
      });
    }
    setLoading(false);
  };

  const checkCanSubtract = (customerEntitlements) => {
    if (customerEntitlements) {
      let peakHourReservationsAvailable = customerEntitlements?.['Peak-Hour Reservations'] || 0;
      let peakHoursToBeRemoved = customerEntitlements?.['Number of Additional Peak Visits Will Be Removed'] || 0;
      let canSubtract = peakHourReservationsAvailable - 1 > peakHoursToBeRemoved;

      return canSubtract;
    }

    return false;
  };

  const retryPaymentAmount = async (failureData) => {
    setLoading(true);
    const response = await Api(`/customer/membership/pay/failed-ordered`, 'POST', {
      ...failureData,
    });

    console.log('response: ', response);

    if (response?.status === 200 && response.data?.success) {
      setActivePause(null);
      setActiveFail(null);
      setFailureReasons([]);

      renderToastSuccess({
        message: response.data.message,
      });
      getCustomer();
      getFaledOrders();
      getActivePause();
      getPeakSessions();
    } else {
      console.log('thus is the error for retry', response);
      renderToastError({
        message: response?.response.data.message || 'An error occured, please try again.',
      });
      setShowCardForm(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getFaledOrders();
    getActivePause();
    getPeakSessions();
  }, []);

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover py-6"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-row items-center px-6 mb-4">
        <BackButton />
        <h6>Membership details</h6>
      </div>
      {loading || customerLoading ? (
        <CircularProgressBar marginTop="mt-2" />
      ) : customerSubscription ? (
        <div className="rounded flex flex-col px-6 overflow-y-auto max-h-[80vh]">
          {customerMembership &&
            activePause === null &&
            activeFail === null &&
            customerMembership.cancellationDate === '' && (
              <div className="flex flex-row justify-between">
                <div className="flex flex-row bg-xtraNavy30">
                  <div className="flex items-center pl-3 pr-4 py-1 border border-xtraWhite30 rounded bg-xtraNavy20">
                    <span className="h-2.5 w-2.5 bg-xtraSuccess rounded-full mr-2"></span>
                    <span className="text-white"> Active </span>
                  </div>
                </div>
                <div
                  className="flex items-center px-8 py-1 border border-xtraWhite rounded bg-xtraNavy20"
                  onClick={() => {
                    navigate('/pause-membership');
                  }}
                >
                  <span className="font-bold text-white text-sm">Pause</span>
                </div>
              </div>
            )}
          {customerMembership.cancellationDate !== '' && (
            <div className="flex flex-col bg-xtraNavy30 border border-xtraWhite30 rounded px-4 py-4">
              <p className="text-sm text-xtraWhite font-bold">Status</p>
              <div className="mt-1 w-full bg-xtraCancelled p-2 pl-3 rounded">
                <p>
                  {moment(customerMembership.cancellationDate).isAfter(moment()) ? 'Cancellation Pending' : 'Cancelled'}
                </p>
              </div>
              <p className="text-sm text-xtraWhite font-bold mt-3">Final day of access</p>
              <div className="mt-1 w-full bg-xtraWhite10 p-2 pl-3 rounded">
                <p>{moment(customerMembership.cancellationDate).format('DD MMMM YYYY')}</p>
              </div>
              <button className="w-full text-sm mt-5" onClick={() => navigate('/reconsider-membership')}>
                Reactivate membership
              </button>
            </div>
          )}
          {customerMembership.cancellationDate === '' && activePause !== null && (
            <div className="flex flex-col bg-xtraNavy30 border border-xtraWhite30 rounded px-4 py-4">
              <p className="text-sm text-xtraWhite font-bold">Status</p>
              <div className="mt-1 w-full flex items-center p-2 pl-3 rounded bg-xtraWhite10">
                <span className="h-2.5 w-2.5 bg-xtraOrange rounded-full mr-2"></span>
                <span className="text-white"> Pause </span>
              </div>
              <p className="text-sm text-xtraWhite font-bold mt-3">Days remaining</p>
              <div className="mt-1 w-full bg-xtraWhite10 p-2 pl-3 rounded">
                <p>{moment(activePause.endDateUTC).diff(moment(activePause.startDateUTC), 'days')}</p>
              </div>
              <p className="text-sm text-xtraWhite font-bold mt-3">End Date</p>
              <div className="mt-1 w-full bg-xtraWhite10 p-2 pl-3 rounded">
                <p>{moment(activePause.endDateUTC).format('DD MMMM YYYY')}</p>
              </div>
              <button className="w-full text-sm mt-5" onClick={() => setShowRestartDrawer(true)}>
                Resume membership
              </button>
            </div>
          )}
          {customerMembership.cancellationDate === '' && activeFail !== null && activeFail.length > 0 && (
            <div className="flex flex-col bg-xtraNavy30 border border-xtraWhite30 rounded px-4 py-4">
              <div className="flex flex-row w-full justify-between">
                <div className="flex flex-col">
                  <p className="text-sm text-xtraWhite font-bold">Status</p>
                  <div className="mt-1 w-full flex items-center p-2 pl-3 rounded bg-xtraWhite10">
                    <span className="h-2.5 w-2.5 bg-xtraError rounded-full mr-2"></span>
                    <span className="text-white"> Overdue </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm text-xtraWhite font-bold">Attempts</p>
                  <div className="mt-1 w-full flex items-center p-2 pl-3 rounded bg-xtraWhite10">
                    <span className="text-white"> {activeFail[0].failure_reasons.length} </span>
                  </div>
                </div>
                {activeFail.next_retry_date != null && (
                  <div className="flex flex-col">
                    <p className="text-sm text-xtraWhite font-bold">Next Retry</p>
                    <div className="mt-1 w-full flex items-center p-2 pl-3 rounded bg-xtraWhite10">
                      <span className="h-2.5 w-2.5 bg-xtraError rounded-full mr-2"></span>
                      <span className="text-white">
                        {' '}
                        {moment(activeFail[0].next_retry_date).diff(moment(), 'hours')}{' '}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col mt-4">
                <p className="text-sm text-xtraWhite font-bold">Failed payment attempts</p>
                {failureReasons.map(function (fail) {
                  console.log('failureReasons: ', fail);
                  return (
                    <div className="mt-1 w-full flex flex-row items-center p-2 pl-3 rounded bg-xtraWhite10 justify-between mb-2">
                      <p className="text-xtraWhite50 w-1/3">{moment(fail.date).format('MMMM D, YYYY h:mma')}</p>
                      <div className="w-2/3 h-[80px] overflow-y-auto">
                        <p>{fail.reason}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col mt-2">
                <div className="mt-1 w-full flex flex-col items-center p-2 pl-3 rounded bg-xtraWhite10 justify-center">
                  <p className="text-xtraWhite50">Owing</p>
                  <p className="text-2xl mt-2">${activeFail[0].total_to_charge}</p>
                  <button
                    className="w-[95%] mt-4 mb-2"
                    onClick={() => {
                      retryPaymentAmount(activeFail[0]);
                    }}
                  >
                    Retry Payment
                  </button>
                </div>
              </div>
            </div>
          )}
          {customerMembership && (
            <div className="flex flex-col mt-3 relative">
              {customerMembership.cancellationDate === '' && activeFail !== null && (
                <div className="absolute inset-0 backdrop-blur-sm flex items-center justify-center">
                  <div className="w-3/4 p-4 rounded-lg shadow-lg text-center bg-xtraNavy70 flex flex-col justify-center items-center">
                    <img src={alertIcon} alt="Alert Icon" className="w-20 h-20 mb-4 object-contain" />
                    <p className="text-white text-center">
                      You must settle your account before accessing your membership settings.
                    </p>
                  </div>
                </div>
              )}
              <div className="flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center">
                <div className="flex flex-col px-4 py-3">
                  <p className="text-sm text-xtraWhite50">Tier</p>
                  <p className="font-bold text-2xl">{customerMembershipProductName}</p>
                  {customerMembershipProduct?.['downgrade_at_next_renewal'] && (
                    <p className="text-xs mt-2">
                      Downgrading to {customerMembershipProduct?.['downgrade_at_next_renewal']}
                    </p>
                  )}
                </div>
                {customerMembership.cancellationDate === '' && (
                  <div className="mr-4">
                    <button
                      className="text-sm rounded-full px-5"
                      onClick={() => {
                        navigate('/change-membership', {
                          state: {
                            activeMembership: activeMembership,
                          },
                        });
                      }}
                    >
                      Modify
                    </button>
                  </div>
                )}
              </div>
              {customerMembership.cancellationDate === '' && (
                <>
                  <div className="flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3">
                    <div className="flex flex-col px-4 py-3">
                      <p className="text-sm text-xtraWhite50">Next payment date</p>
                      <p className="font-bold text-2xl">
                        {moment(customerMembership.nextPaymentDate).format('Do MMM YY')}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3">
                    <div className="flex flex-col px-4 py-3">
                      <p className="text-sm text-xtraWhite50">Next payment amount</p>
                      <p className="font-bold text-2xl">{customerMembership.nextPaymentAmount}</p>
                    </div>
                  </div>
                </>
              )}
              <div className="flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3">
                <div className="flex flex-col px-4 py-3">
                  <p className="text-sm text-xtraWhite50">Peak sessions / week</p>
                  {!serviceLoading.peakSession && (
                    <>
                      <p className="font-bold text-2xl">
                        {!isThrive
                          ? customerEntitlements?.['Peak-Hour Reservations'] ?? 0 + peakSessionPurchased?.length ?? 0
                          : 'Unlimited'}
                      </p>
                      {!isThrive && customerEntitlements?.['Number of Additional Peak Visits Will Be Removed'] > 0 && (
                        <p className="text-xs mt-3">
                          Number of peak session visits to be removed:{' '}
                          <b>{customerEntitlements?.['Number of Additional Peak Visits Will Be Removed']}</b>
                        </p>
                      )}
                    </>
                  )}
                </div>
                {customerMembership.cancellationDate === '' && (
                  <div className="flex flex-row gap-2.5 mr-4">
                    {serviceLoading.peakSession ? (
                      <div>
                        <CircularProgressBar width={30} height={30} marginTop="" />
                      </div>
                    ) : (
                      <>
                        {!activeMembership?.product_name.includes('Thrive') &&
                          checkCanSubtract(customerEntitlements) && (
                            <button
                              className="rounded-full bg-transparent border border-xtraWhite30 text-sm w-full"
                              onClick={() => {
                                handlePeakSessionClick(true);
                              }}
                            >
                              Subtract
                            </button>
                          )}
                        {!activeMembership?.product_name.includes('Thrive') && (
                          <button
                            className="rounded-full text-sm w-full"
                            onClick={() => {
                              handlePeakSessionClick(false);
                            }}
                          >
                            Increase
                          </button>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3">
                <div className="flex flex-col px-4 py-3">
                  <p className="text-sm text-xtraWhite50">Session duration</p>
                  {!serviceLoading.sessionDuration && (
                    <>
                      <p className="font-bold text-2xl">
                        {!isThrive
                          ? `${
                              customerMembership
                                ? customerEntitlements?.['Session Duration'] ? customerEntitlements?.['Session Duration'] + ' minutes' : "0"
                                : '90 minutes'
                            }`
                          : 'Unlimited'}
                      </p>
                      {customerEntitlements?.['Extended Session Time Will Be Removed'] === true && (
                        <p className="text-xs mt-3">Extended session time will be removed</p>
                      )}
                    </>
                  )}
                </div>
                {customerMembership.cancellationDate === '' && (
                  <div className="flex flex-row gap-2.5 mr-4">
                    {serviceLoading.sessionDuration ? (
                      <div>
                        <CircularProgressBar width={30} height={30} marginTop="" />
                      </div>
                    ) : (
                      <div className="flex flex-row gap-2.5">
                        {!customerMembershipHasExtendedSession
                          ? !isThrive && (
                              <button className="rounded-full text-sm" onClick={handleSessionChangeClick}>
                                Increase
                              </button>
                            )
                          : !customerEntitlements?.['Extended Session Time Will Be Removed'] && (
                              <button
                                className="rounded-full bg-transparent border border-xtraWhite30 text-sm"
                                onClick={handleSessionChangeClick}
                              >
                                Subtract
                              </button>
                            )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3">
                <div className="flex flex-col px-4 py-3">
                  <p className="text-sm text-xtraWhite50">Towel service</p>
                  {!serviceLoading.towelService && (
                    <>
                      <p className="font-bold text-2xl">
                        {customerEntitlements?.['Towel Service'] ? 'Included' : 'Not Included'}
                      </p>
                      {customerEntitlements?.['Towel Service Will Be Removed'] === true && (
                        <p className="text-xs mt-3">Towel service will be removed</p>
                      )}
                    </>
                  )}
                </div>
                {serviceLoading.towelService ? (
                  <div>
                    <CircularProgressBar width={30} height={30} marginTop="" />
                  </div>
                ) : (
                  customerMembership &&
                  customerMembership.cancellationDate === '' && (
                    <div className="flex flex-row gap-2.5 mr-4">
                      {!isThrive && customerEntitlements?.['Towel Service']
                        ? customerEntitlements?.['Towel Service Will Be Removed'] !== true && (
                            <button
                              className="rounded-full bg-transparent border border-xtraWhite30 text-sm"
                              onClick={handleTowelChangeClick}
                            >
                              Downgrade
                            </button>
                          )
                        : !isThrive && (
                            <button className="rounded-full text-sm" onClick={handleTowelChangeClick}>
                              Upgrade
                            </button>
                          )}
                    </div>
                  )
                )}
              </div>
              <div className="flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-between items-center mt-3">
                <div className="flex flex-col px-4 py-3">
                  <p className="text-sm text-xtraWhite50">Off-peak session / week</p>
                  <p className="font-bold text-2xl">Unlimited</p>
                </div>
              </div>
              {customerMembership.cancellationDate === '' && activeFail === null && (
                <div
                  className="flex flex-row bg-xtraNavy30 border border-xtraWhite30 rounded justify-center items-center mt-6 mb-6"
                  onClick={() => {
                    navigate('/cancel-membership');
                  }}
                >
                  <div className="flex flex-col px-4 py-3">
                    <p className="text-sm text-xtraWhite50">Cancel Membership</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="px-5">
          <p>Sorry, your subscription details were not able to be retrieved. Please try again.</p>
        </div>
      )}

      {/* Conditionally render the UpdateCardForm component as a modal */}
      {showCardForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-xtraNavy rounded-lg p-6 w-11/12 max-w-md relative">
            <h2 className="text-xl font-bold text-center mb-2">Update Card Details</h2>
            <p className="text-center text-white mb-6">
              Your current card is not valid. Please update your card details to continue. Once you have updated your
              card, please try again.
            </p>
            <UpdateCardForm onSuccess={() => setShowCardForm(false)} onFailure={() => setShowCardForm(false)} />
            <button
              className="absolute top-2 right-2 text-white hover:text-white"
              onClick={() => setShowCardForm(false)}
            >
              &times;
            </button>
          </div>
        </div>
      )}

      {/* Error drawer to trigger UpdateCardForm if needed */}
      <ErrorSlideUpDrawer
        setShow={setErrorSlideUp}
        show={errorSlideUp}
        action={() => setShowCardForm(true)} // Directly toggle showCardForm
        actionText="Update Card Details"
        actionHeading="Update Card Details"
        message="Looks like your payment did not go through successfully. Please update your card details to proceed."
        actionRightAway={true}
      />
      <SlideUpDrawer
        show={showDrawer}
        setShow={setShowDrawer}
        action={selectedOption.action}
        actionText={selectedOption.text}
        actionClass={selectedOption}
        message={popupMessage ?? ''}
        loading={!popupMessage}
      />
      <RestartSlideUpDrawer
        setShow={setShowRestartDrawer}
        show={showRestartDrawer}
        action={restartMembership}
        actionText={'Re-Activate Membership'}
        actionHeading={'Re-Activate membership'}
        message={
          'By re-activating, your membership will be started again from today and you will be able to reserve a session time and access Xtra Clubs. Continue?'
        }
        actionRightAway={true}
      />
      <PeakSessionsDrawer
        setShow={showPeakSessionDrawer}
        show={peakSessionDrawer}
        activeMembership={activeMembership}
        membershipDetails={{ entitlements: customerEntitlements }}
        peakSessionPurchased={peakSessionPurchased}
        handlePeakSessionClick={handlePeakSessionClick}
        handlePurchaseSinglePeakVisit={() => {
          showPeakSessionDrawer(false);
          setPeakVisitLoading(true);
          handlePurchaseSinglePeakVisit({
            returnPrice: 1,
            setDisplaySinglePeakVisitPurchase,
            setPeakVisitLoading,
            setSinglePeakVisitInfo,
            successFunctionCalls: () => getCustomer({ update: true }),
          });
        }}
      />
      {displaySinglePeakVisitPurchase && (
        <SlideUpDrawer
          setShow={setDisplaySinglePeakVisitPurchase}
          show={displaySinglePeakVisitPurchase}
          action={() => {
            handlePurchaseSinglePeakVisit({
              returnPrice: 0,
              setDisplaySinglePeakVisitPurchase,
              setPeakVisitLoading,
              setSinglePeakVisitInfo,
              successFunctionCalls: () => getCustomer({ update: true }),
            });
          }}
          actionText={`Pay ${singlePeakVisitInfo?.price}`}
          actionHeading={'Add a once-off peak visit'}
          message={`${singlePeakVisitInfo?.message}`}
          actionRightAway={false}
          loading={peakVisitLoading}
        />
      )}
    </div>
  );
};

export default MembershipDetails;
