import React, { useEffect, useState, useRef, useCallback } from 'react';
import TabBarNavigator from '../components/TabBarNavigator';
import xtraBG from '../images/xtra-bg.jpg';
import ReservationsList from '../components/ReservationsList';
import { useNavigate, useLocation } from 'react-router-dom';
import SafeAreaHeader from '../components/SafeAreaHeader';
import moment from 'moment-timezone';
import Api from '../components/Api';
import CircularProgressBar from '../components/CircularProgressBar';
import SlideUpDrawer from '../components/SlideUpDrawer';
import withdrawReservation from '../constants/withdraw';
import bluePlus from '../images/blue-plus.png';
import { ReactComponent as PlanIcon } from '../assets/tabBar/Plan.svg';
import { ReactComponent as HelpIcon } from '../assets/tabBar/Help.svg';
import AccountIcon from '../components/AccountIcon';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import renderToastError from '../renderFunctions/renderToastError';
import { getCustomerActiveSession } from '../constants/getCustomerSession';
import xtraTick from '../images/xtra-tick.png';
import { formatTimeSchedule } from '../renderFunctions/formatTime';
import orangeWarning from '../images/orangeWarningMark.png';
import { useCustomer } from '../contexts/CustomerContext';

const YourSessions = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { customer } = useCustomer();
  const [page, setPage] = useState(1);
  const [customerQueue, setCustomerQueue] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState('');
  const [pageEnd, setPageEnd] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [undoInProgress, setUndoInProgress] = useState(null);
  const [theWaitlist, setCustomerWaitlist] = useState([]);
  const [showMissSession, setShowMissSession] = useState(false);
  const [showMove, setShowMoving] = useState(false);
  const [singlePeakVisitInfo, setSinglePeakVisitInfo] = useState(null);
  const queueIntervalRef = useRef();
  const [displaySinglePeakVisitPurchase, setDisplaySinglePeakVisitPurchase] = useState(false);

  const getCustomerQueue = async (page = 1) => {
    var date = moment().format();
    var time = moment().format('H:mm');
    try {
      const queue = await Api(`/schedule/customerqueue/${page}?date=${date}&time=${time}&pageSize=20`, 'GET');
      if (queue.status === 200) {
        let result = queue.data;
        let customerQueue = result.queue;
        if (result.success) {
          if (customerQueue.length > 0) {
            if (page !== 1) {
              setCustomerQueue((prevItems) => [...prevItems, ...result.queue]);
            } else {
              setPageEnd(false);
              for (let q = 0; q < customerQueue.length; q++) {
                const queueItem = customerQueue[q];
                if (queueItem.date.split('T')[0] === moment().format('YYYY-MM-DD')) {
                  const start = parseFloat(queueItem.time);
                  const end = start + 0.5;

                  let endTime = end;
                  if (String(endTime).includes('.5')) {
                    endTime = endTime + ':30';
                  } else {
                    endTime = endTime + ':00';
                  }

                  const d = new Date();
                  const currentHour = d.getHours();
                  const currentMinute = d.getMinutes();
                  const currentTime = currentHour + currentMinute / 60;

                  const afterEndTimeDiff = moment(d).diff(moment(endTime, 'H:mm'), 'minutes');

                  if (currentTime > end && afterEndTimeDiff >= 30) {
                    customerQueue.splice(q, 1);
                  }
                }
              }
              setCustomerQueue(customerQueue);
            }
          } else {
            setPageEnd(true);
          }
        }
      } else {
        setLoading(false);
        renderToastError(
          'There was an issue retrieving your sessions. Please try again, if the issue persists, please contact us.'
        );
        // setError("There was an error completing your reservation.");
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    setPageLoading(false);
    setLoading(false);
  };

  const getCustomerWaitlist = async () => {
    var date = moment().format();
    try {
      const wlist = await Api(`/schedule/waitlist?date=${date}`, 'GET');
      if (wlist.status === 200) {
        let result = wlist.data;
        let customerWaitlist = result.waitlist;
        if (result.success) {
          setCustomerWaitlist(customerWaitlist);
        }
      } else {
        renderToastError({
          error:
            'There was an issue retrieving your waitlist. Please try again, if the issue persists, please contact us.',
        });
      }
    } catch (error) {
      renderToastError({ message: error });
    }
  };

  const withdraw = async () => {
    let undoReservations = [selectedReservation];
    const fadeOutDuration = 3000;
    // Withdraw only the selected reservation
    let now = moment();
    var timeWithMinutes = String(selectedReservation.time);
    timeWithMinutes = timeWithMinutes.includes('.5') ? timeWithMinutes + ':30' : timeWithMinutes + ':00';
    let startTime = moment(selectedReservation.date.split('T')[0] + ' ' + timeWithMinutes, 'YYYY-MM-DD H:mm');

    let differenceInMins = startTime.diff(now, 'minutes');
    if (now.isSame(startTime, 'day') && differenceInMins <= 15) {
      renderToastError({
        message:
          'Sorry, you cannot withdraw your reservation from within 15 minutes of the start time. Your session credit is now used.',
      });
    } else {
      setUndoInProgress(true);
      await withdrawReservation({
        customerQueue: customerQueue,
        undoReservations: undoReservations,
        message: 'There was an error trying to withdraw your reservation. Please contact us for help.',
        fadeOutDuration: fadeOutDuration,
        setCustomerQueue: setCustomerQueue,
        setUndoInProgress: setUndoInProgress,
      });
      setSelectedReservation(null);
    }
  };

  const checkActiveSession = useCallback(async () => {
    const activeSession = await getCustomerActiveSession();
    if (activeSession) {
      clearQueueInterval();
      navigate(`/session?id=${activeSession._id}`);
    }
  }, [navigate]);

  const clearQueueInterval = () => {
    if (queueIntervalRef.current) {
      clearInterval(queueIntervalRef.current);
    }
  };

  const handlePushBackPress = async () => {
    setShowMissSession(false);
    if (selectedReservation) {
      const response = await Api('/customer/schedule/move', 'POST', {
        currentSchedule: selectedReservation,
        moveToSchedule: selectedReservation?.next,
        checkPeak: !selectedReservation?.isPeak && selectedReservation?.next?.isPeak,
      });
      if (response.status === 200) {
        if (getCustomerQueue !== null) {
          getCustomerQueue(1);
        }
        if (response.data.success) {
          renderToastSuccess({
            message: response.data.message,
          });
        } else {
          renderToastError({
            message: response.data.message,
          });
        }
      } else {
        if (response.response?.data?.purchasePeak) {
          setShowMissSession(false);
          await purchaseSinglePeakVisit(1);
          setDisplaySinglePeakVisitPurchase(true);
        } else {
          renderToastError({
            message: response.response.data.message,
          });
        }
      }
    } else {
      renderToastError({
        error: 'There was an issue retrieving your reservation. Please try again or contact us for assistance.',
      });
    }
  };

  const purchaseSinglePeakVisit = async (returnPrice = 0) => {
    setLoading(true);
    const purchaseSinglePeak = await Api(
      `/customer/membership/purchase/singlepeakvisit?returnPrice=${returnPrice}&siteid=${selectedReservation?.site}`,
      'GET'
    );
    if (purchaseSinglePeak.status === 200) {
      let result = purchaseSinglePeak.data;
      if (result.success) {
        if (returnPrice === 1) {
          setSinglePeakVisitInfo(result.peakSession);
        } else {
          handlePushBackPress();
          renderToastSuccess({
            message: result.peakSession?.message ?? 'Single peak visit purchased!',
          });
        }
      } else {
        renderToastError({
          message: result.message,
        });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state?.notification) {
      const handleTappedNotification = async () => {
        const { title, scheduleId } = state?.notification;

        if (title === 'Reminder: Session Upcoming' && scheduleId) {
          const response = await Api(`/schedule/${scheduleId}?missing=true`, 'GET');

          if (response.status === 200 && response.data.reservation) {
            const reservation = { ...response.data.reservation, next: response.data.nextReservation };
            setSelectedReservation(reservation);
            setShowMoving(false);
            setShowMissSession(true);
          }
        }
      };

      handleTappedNotification();
    }
  }, [state]);

  useEffect(() => {
    getCustomerWaitlist();
    getCustomerQueue();

    const now = new Date();
    const delay = (60 - now.getSeconds()) * 1000 - now.getMilliseconds();
    checkActiveSession();

    const activeInterval = setInterval(() => {
      checkActiveSession();
    }, 10000);

    const timeout = setTimeout(() => {
      const runAtMinuteChange = () => {
        getCustomerQueue();
      };

      runAtMinuteChange();
      const interval = setInterval(runAtMinuteChange, 10000);
      queueIntervalRef.current = interval;
    }, delay);

    return () => {
      clearInterval(activeInterval);
      clearTimeout(timeout);
    };
  }, [checkActiveSession]);

  useEffect(() => {
    if (page !== 1 && pageLoading === false) {
      setPageLoading(true);
      getCustomerQueue(page);
    }
  }, [page, pageLoading]);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover p-6"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center mb-4">
          <h3 className="font-recoleta">Your sessions</h3>
          <div className="flex flex-row">
            <img
              src={bluePlus}
              className="w-9 h-9 rounded-full mr-2"
              alt="blue plus"
              onClick={() => {
                clearQueueInterval();
                if (state?.notification) {
                  navigate(pathname, { state: null, replace: true });
                }
                navigate('/reserve');
              }}
            />
            <AccountIcon
              yourDetails={customer}
              handleClick={() => {
                clearQueueInterval();
                if (state?.notification) {
                  navigate(pathname, { state: null, replace: true });
                }
                navigate('/you');
              }}
              noPhoto={false}
            />
          </div>
        </div>
        {loading ? (
          <div>
            <CircularProgressBar />
          </div>
        ) : customerQueue.length > 0 || theWaitlist.length !== 0 ? (
          <ReservationsList
            reservations={customerQueue}
            page={page}
            setPage={setPage}
            pageLoading={pageLoading}
            pageEnd={pageEnd}
            setShowDrawer={setShowDrawer}
            setSelectedReservation={setSelectedReservation}
            undoInProgress={undoInProgress}
            theWaitlist={theWaitlist}
            getCustomerQueue={() => {
              console.log('this function was called: getCustomerQueue');
              setLoading(true);
              getCustomerQueue();
            }}
          />
        ) : (
          <div className="flex flex-col">
            <p>Hey, looks like you have nothing scheduled...</p>
          </div>
        )}
        <button
          className="mt-3"
          onClick={() => {
            clearQueueInterval();
            if (state?.notification) {
              navigate(pathname, { state: null, replace: true });
            }
            navigate('/reserve');
          }}
        >
          Reserve
        </button>
      </div>
      <TabBarNavigator
        tabs={[
          { name: 'Sessions', Icon: PlanIcon, route: '/your-sessions' },
          { name: 'Help', Icon: HelpIcon, route: '/help' },
        ]}
        paddingHorizontal={80}
      />
      <SlideUpDrawer
        show={showDrawer}
        setShow={setShowDrawer}
        action={withdraw}
        actionText={'Withdraw'}
        message={
          'By withdrawing from the scheduled time, if you have used a session package or gift, the session will be returned.'
        }
      />
      <SlideUpDrawer
        show={showMissSession}
        setShow={setShowMissSession}
        customContent={
          <div className="flex flex-col pb-8">
            <h4 className="mb-5">{showMove ? 'Push back your session' : 'Going to miss your session?'}</h4>
            {selectedReservation && (
              <div className="bg-xtraWhite10 p-4 rounded">
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs">{showMove ? 'New arrival between' : 'Arrival between'}</p>
                    <p className="text-sm font-bold">
                      {formatTimeSchedule(showMove ? selectedReservation.next?.time : selectedReservation.time)}
                    </p>
                  </div>
                  {!showMove && (
                    <p className="flex items-center text-xs bg-xtraSuccess h-fit text-xtraNavy px-[5px] py-[2px] font-medium mr-2 rounded">
                      <img className="w-3 h-3 object-fit mr-1" src={xtraTick} alt="xtra tick" />
                      Reserved
                    </p>
                  )}
                  {showMove && selectedReservation?.next?.isPeak && (
                    <p className={`slot-peak mr-2 rounded-full pr-2 pl-2 pt-1 pb-1 text-xs`}>Peak</p>
                  )}
                </div>
                <div className="flex flex-col gap-3 mt-5">
                  {showMove ? (
                    <button onClick={handlePushBackPress}>Continue</button>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          setShowMissSession(false);
                          setShowDrawer(true);
                        }}
                      >
                        Withdraw session
                      </button>
                      {selectedReservation?.next && (
                        <button onClick={() => setShowMoving(true)}>Move to next session</button>
                      )}
                    </>
                  )}
                </div>
                {showMove && selectedReservation?.next?.isPeak && (
                  <div className="flex flex-row justify-center items-center gap-1 mt-4">
                    <img src={orangeWarning} alt="warning" className="object-fit w-4 h-4" />
                    <p className="text-xs">Your new session time is a peak session.</p>
                  </div>
                )}
              </div>
            )}
            <button
              className="cancel mt-5"
              onClick={() => {
                setShowMissSession(false);
                setSelectedReservation(false);
              }}
            >
              Close
            </button>
          </div>
        }
      />
      <SlideUpDrawer
        setShow={setDisplaySinglePeakVisitPurchase}
        show={displaySinglePeakVisitPurchase}
        action={purchaseSinglePeakVisit}
        actionText={`Pay ${singlePeakVisitInfo?.price}`}
        actionHeading={'Add a once-off peak visit'}
        message={`${singlePeakVisitInfo?.message}`}
        actionRightAway={false}
      />
    </div>
  );
};

export default YourSessions;
